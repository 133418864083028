import React from "react";
import { Footer, Header, SchemaEditor } from "./Components";

export function App() {
	return (
		<React.Fragment>
			<Header />
			<main>
				<SchemaEditor />
			</main>
			<Footer />
		</React.Fragment>
	);
}
