import { FieldFormValues } from "./field";

export type ModelPropertyFormValues = {
	hasPropertyAPIImmutable: boolean;
	hasPropertyAPIPrivate: boolean;
	hasPropertyAPIReadOnly: boolean;
	hasPropertyAPIUpdateOnly: boolean;
	hasPropertyNoGlobalSearch: boolean;
	hasPropertyNoHistory: boolean;
	hasPropertyNoPermissions: boolean;
};

export type ModelFormValues = {
	name: string;
	description: string;
	tableName: string;
	fields: FieldFormValues[];
} & ModelPropertyFormValues;

export const blankModelPropertyFormValues: ModelPropertyFormValues = {
	hasPropertyAPIImmutable: false,
	hasPropertyAPIPrivate: false,
	hasPropertyAPIReadOnly: false,
	hasPropertyAPIUpdateOnly: false,
	hasPropertyNoGlobalSearch: false,
	hasPropertyNoHistory: false,
	hasPropertyNoPermissions: false,
};

export const blankModelFormValues: ModelFormValues = {
	name: "",
	description: "",
	tableName: "",
	fields: [],
	...blankModelPropertyFormValues,
};

export function countActiveModelProperties(formValues: ModelPropertyFormValues) {
	let count = 0;
	if (formValues.hasPropertyAPIImmutable) {
		count++;
	}
	if (formValues.hasPropertyAPIPrivate) {
		count++;
	}
	if (formValues.hasPropertyAPIReadOnly) {
		count++;
	}
	if (formValues.hasPropertyAPIUpdateOnly) {
		count++;
	}
	if (formValues.hasPropertyNoGlobalSearch) {
		count++;
	}
	if (formValues.hasPropertyNoHistory) {
		count++;
	}
	if (formValues.hasPropertyNoPermissions) {
		count++;
	}
	return count;
}
