export enum ConvenientEdgeType {
	OneToOne = "OneToOne",
	OneToMany = "OneToMany",
	ManyToOne = "ManyToOne",
	ManyToMany = "ManyToMany",
}

export const onDeletePropertyValueOptions = [
	{ value: "Cascade" },
	{ value: "NoAction" },
	{ value: "Restrict" },
	{ value: "SetDefault" },
	{ value: "SetNull" },
];

export enum CreateFormFieldType {
	Nested = "Nested",
	Select = "Select",
	Omit = "Omit",
}

export const createFormFieldTypeOptions = [
	{ value: CreateFormFieldType.Nested },
	{ value: CreateFormFieldType.Select },
	{ value: CreateFormFieldType.Omit },
];

export enum DetailFormFieldType {
	Select = "Select",
	Omit = "Omit",
}

export const detailFormFieldTypeOptions = [{ value: DetailFormFieldType.Select }, { value: DetailFormFieldType.Omit }];

export type EdgePropertyFormValues = {
	hasPropertyAPIPrivate: boolean;
	hasPropertyAPIReadOnly: boolean;
	hasPropertyImmutable: boolean;
	hasPropertyOnDelete: boolean;
	propertyValueOnDelete: string;
	leftHasPropertyAPIUpdateOnly: boolean;
	leftHasPropertyRequired: boolean;
	leftHasPropertySearchable: boolean;
	leftPropertyValueCreateFormFieldType: CreateFormFieldType | null;
	leftPropertyValueDetailFormFieldType: DetailFormFieldType | null;
	rightHasPropertyAPIUpdateOnly: boolean;
	rightHasPropertyRequired: boolean;
	rightHasPropertySearchable: boolean;
	rightPropertyValueCreateFormFieldType: CreateFormFieldType | null;
	rightPropertyValueDetailFormFieldType: DetailFormFieldType | null;
};

export type EdgeFormValues = {
	source: string;
	sourceAlias: string;
	edgeType: ConvenientEdgeType;
	target: string;
	targetAlias: string;
} & EdgePropertyFormValues;

export const edgeOptions = [
	{ label: "One to One", value: ConvenientEdgeType.OneToOne },
	{ label: "One to Many", value: ConvenientEdgeType.OneToMany },
	{ label: "Many to One", value: ConvenientEdgeType.ManyToOne },
	{ label: "Many to Many", value: ConvenientEdgeType.ManyToMany },
];

const blankEdgePropertyFormValues: EdgePropertyFormValues = {
	hasPropertyAPIPrivate: false,
	hasPropertyAPIReadOnly: false,
	hasPropertyImmutable: false,
	hasPropertyOnDelete: false,
	propertyValueOnDelete: "",
	leftHasPropertyAPIUpdateOnly: false,
	leftHasPropertyRequired: false,
	leftHasPropertySearchable: false,
	leftPropertyValueCreateFormFieldType: null,
	leftPropertyValueDetailFormFieldType: null,
	rightHasPropertyAPIUpdateOnly: false,
	rightHasPropertyRequired: false,
	rightHasPropertySearchable: false,
	rightPropertyValueCreateFormFieldType: null,
	rightPropertyValueDetailFormFieldType: null,
};

export const blankEdgeFormValues: EdgeFormValues = {
	source: "",
	sourceAlias: "",
	edgeType: ConvenientEdgeType.OneToOne,
	target: "",
	targetAlias: "",
	...blankEdgePropertyFormValues,
};

export function countActiveEdgeProperties(formValues: EdgePropertyFormValues) {
	let count = 0;
	if (formValues.hasPropertyAPIPrivate) {
		count++;
	}
	if (formValues.hasPropertyAPIReadOnly) {
		count++;
	}
	if (formValues.hasPropertyImmutable) {
		count++;
	}
	if (formValues.hasPropertyOnDelete) {
		count++;
	}
	if (formValues.leftHasPropertyAPIUpdateOnly) {
		count++;
	}
	if (formValues.leftPropertyValueCreateFormFieldType) {
		count++;
	}
	if (formValues.leftPropertyValueDetailFormFieldType) {
		count++;
	}
	if (formValues.leftHasPropertyRequired) {
		count++;
	}
	if (formValues.leftHasPropertySearchable) {
		count++;
	}
	if (formValues.rightHasPropertyAPIUpdateOnly) {
		count++;
	}
	if (formValues.rightPropertyValueCreateFormFieldType) {
		count++;
	}
	if (formValues.rightPropertyValueDetailFormFieldType) {
		count++;
	}
	if (formValues.rightHasPropertyRequired) {
		count++;
	}
	if (formValues.rightHasPropertySearchable) {
		count++;
	}
	return count;
}
