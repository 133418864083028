import React from "react";
import { Button, Heading, List, Modal, Row } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";
import {
	containsEdge,
	DeleteFunction,
	Edge,
	EdgeFormValues,
	Model,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../Utilities";
import { EdgeForm } from "../Forms";
import styles from "./styles.module.css";

export type EdgeListProps = {
	edges: Edge[];
	models: Model[];
	update: UpdateFunction<Edge>;
	del: DeleteFunction;
	disabled?: boolean;
};
export function EdgeList({ edges, models, update, del, disabled }: EdgeListProps) {
	const validate = React.useCallback(
		(key: string, formValues: EdgeFormValues) => {
			if (containsEdge(edges, formValues, key)) {
				return invalid("Edge already exists");
			}
			return valid();
		},
		[edges],
	);

	if (edges.length === 0) {
		return null;
	}

	return (
		<List className={styles["edge-list"]}>
			{edges.map((edge) => (
				<EdgeListItem
					key={edge.key}
					edge={edge}
					models={models}
					validate={validate}
					update={update}
					del={del}
					disabled={disabled}
				/>
			))}
		</List>
	);
}

type EdgeListItemProps = {
	edge: Edge;
	models: Model[];
	validate: UpdateValidationFunction<EdgeFormValues>;
	update: UpdateFunction<Edge>;
	del: DeleteFunction;
	disabled?: boolean;
};

function EdgeListItem({ edge, models, validate, update, del, disabled }: EdgeListItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<List.Item className={styles["edge-list-item"]}>
				<Row justify="space-between" align="center">
					<code>{edge.toSimpleString()}</code>

					<Button variant="secondary" size="small" disabled={disabled} onClick={toggleIsModalOpen}>
						Edit
					</Button>
				</Row>
			</List.Item>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>Edit Edge</Heading.H2>
					</Modal.Header>

					<Modal.Body>
						<EdgeForm.ControlledUpdate
							edge={edge}
							models={models}
							validate={validate}
							update={update}
							del={del}
							onCancel={toggleIsModalOpen}
							onSuccess={toggleIsModalOpen}
						/>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
