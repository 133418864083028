import React from "react";
import { Button, Heading, List, Modal, Row } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";
import {
	containsMetaTag,
	DeleteFunction,
	MetaTag,
	MetaTagFormValues,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../Utilities";
import { MetaTagForm } from "../Forms";
import styles from "./styles.module.css";

type MetaTagListProps = {
	metaTags: MetaTag[];
	disabled?: boolean;
	update: UpdateFunction<MetaTag>;
	del: DeleteFunction;
};
export function MetaTagList({ metaTags, disabled, update, del }: MetaTagListProps) {
	const validate = React.useCallback(
		(key: string, formValues: MetaTagFormValues) => {
			if (containsMetaTag(metaTags, formValues, key)) {
				return invalid("MetaTag already exists");
			}
			return valid();
		},
		[metaTags],
	);

	if (metaTags.length === 0) {
		return null;
	}

	return (
		<List className={styles["meta-tag-list"]}>
			{metaTags.map((metaTag) => (
				<MetaTagListItem
					key={metaTag.key}
					metaTag={metaTag}
					validate={validate}
					update={update}
					del={del}
					disabled={disabled}
				/>
			))}
		</List>
	);
}

type MetaTagListItemProps = {
	metaTag: MetaTag;
	validate: UpdateValidationFunction<MetaTagFormValues>;
	update: UpdateFunction<MetaTag>;
	del: DeleteFunction;
	disabled?: boolean;
};

function MetaTagListItem({ metaTag, validate, update, del, disabled }: MetaTagListItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<List.Item className={styles["meta-tag-list-item"]}>
				<Row justify="space-between" align="center">
					<code>{metaTag.tag}</code>

					<Button variant="secondary" size="small" disabled={disabled} onClick={toggleIsModalOpen}>
						Edit
					</Button>
				</Row>
			</List.Item>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>Edit Meta Tag</Heading.H2>
					</Modal.Header>

					<Modal.Body>
						<MetaTagForm.ControlledUpdate
							metaTag={metaTag}
							validate={validate}
							update={update}
							del={del}
							onCancel={toggleIsModalOpen}
							onSuccess={toggleIsModalOpen}
						/>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
