import { CaseStyle, convertCase, toPlural } from "@hex-insights/core";
import { ConvenientEdgeType } from "./Forms";

export function getDefaultSourceAlias(source: string, edgeType: ConvenientEdgeType) {
	const value = convertCase(source, CaseStyle.Snake);
	if (edgeType === ConvenientEdgeType.ManyToOne || edgeType === ConvenientEdgeType.ManyToMany) {
		return toPlural(value);
	}
	return value;
}

export function getDefaultTargetAlias(target: string, edgeType: ConvenientEdgeType) {
	const value = convertCase(target, CaseStyle.Snake);
	if (edgeType === ConvenientEdgeType.OneToMany || edgeType === ConvenientEdgeType.ManyToMany) {
		return toPlural(value);
	}
	return value;
}
