import React from "react";
import { useUpdatingRef } from "@hex-insights/core";
import { NormalizationVisitor, SchemaNode } from "@hex-insights/srsl";
import {
	Edge,
	edgeFormValuesToEdge,
	edgeNodeToEdgeFormValues,
	getDefaultSourceAlias,
	getDefaultTargetAlias,
	MetaTag,
	Model,
} from "../../Utilities";

const normalizationVisitor = new NormalizationVisitor();

export function useSchemaEditorState() {
	const [models, setModels] = React.useState<Model[]>([]);
	const modelsRef = useUpdatingRef(models);
	const [edges, setEdges] = React.useState<Edge[]>([]);
	const [metaTags, setMetaTags] = React.useState<MetaTag[]>([]);
	const schema = React.useMemo(() => new SchemaNode(metaTags, models, edges), [metaTags, models, edges]);

	const acceptSchema = React.useCallback((schema: SchemaNode) => {
		setModels(schema.models.map((e) => Model.fromModelNode(e)));
		setEdges(schema.edges.map((e) => Edge.fromEdgeNode(e)));
		setMetaTags(schema.metaTags.map((e) => MetaTag.fromMetaTagNode(e)));
	}, []);

	const createModel = React.useCallback((newModel: Model) => {
		normalizationVisitor.visitModelNode(newModel);
		setModels((prevModels) => [...prevModels, newModel].sort(normalizationVisitor.compareModels));
	}, []);

	const updateModel = React.useCallback(
		(key: string, updatedModel: Model) => {
			const prevModel = modelsRef.current.find((e) => e.key === key);
			if (!prevModel) {
				return;
			}

			normalizationVisitor.visitModelNode(updatedModel);
			setModels((prevModels) =>
				prevModels.map((e) => (e.key === key ? updatedModel : e)).sort(normalizationVisitor.compareModels),
			);

			if (updatedModel.name !== prevModel.name) {
				setEdges((prevEdges) =>
					prevEdges
						.map((prevEdge) => {
							const edgeFormValues = edgeNodeToEdgeFormValues(prevEdge);
							let edgeWasUpdated = false;
							if (edgeFormValues.source === prevModel.name) {
								edgeFormValues.source = updatedModel.name;
								const defaultAlias = getDefaultSourceAlias(prevModel.name, edgeFormValues.edgeType);
								if (edgeFormValues.sourceAlias === defaultAlias) {
									edgeFormValues.sourceAlias = getDefaultSourceAlias(updatedModel.name, edgeFormValues.edgeType);
								}
								edgeWasUpdated = true;
							}
							if (edgeFormValues.target === prevModel.name) {
								edgeFormValues.target = updatedModel.name;
								const defaultAlias = getDefaultTargetAlias(prevModel.name, edgeFormValues.edgeType);
								if (edgeFormValues.targetAlias === defaultAlias) {
									edgeFormValues.targetAlias = getDefaultTargetAlias(updatedModel.name, edgeFormValues.edgeType);
								}
								edgeWasUpdated = true;
							}

							if (edgeWasUpdated) {
								return edgeFormValuesToEdge(edgeFormValues);
							}
							return prevEdge;
						})
						.sort(normalizationVisitor.compareEdges),
				);
			}
		},
		[modelsRef],
	);

	const deleteModel = React.useCallback(
		(key: string) => {
			const model = modelsRef.current.find((e) => e.key === key);
			if (!model) {
				return;
			}
			setModels((prevModels) => prevModels.filter((e) => e.key !== key));
			setEdges((prevEdges) => prevEdges.filter((e) => !(e.target === model.name || e.source === model.name)));
		},
		[modelsRef],
	);

	const createEdge = React.useCallback((newEdge: Edge) => {
		normalizationVisitor.visitEdgeNode(newEdge);
		setEdges((prevEdges) => [...prevEdges, newEdge].sort(normalizationVisitor.compareEdges));
	}, []);

	const updateEdge = React.useCallback((key: string, updatedEdge: Edge) => {
		normalizationVisitor.visitEdgeNode(updatedEdge);
		setEdges((prevEdges) =>
			prevEdges.map((e) => (e.key === key ? updatedEdge : e)).sort(normalizationVisitor.compareEdges),
		);
	}, []);

	const deleteEdge = React.useCallback((key: string) => {
		setEdges((prevEdges) => prevEdges.filter((e) => e.key !== key));
	}, []);

	const createMetaTag = React.useCallback((newMetaTag: MetaTag) => {
		normalizationVisitor.visitMetaTagNode(newMetaTag);
		setMetaTags((prevMetaTags) => [...prevMetaTags, newMetaTag]);
	}, []);

	const updateMetaTag = React.useCallback((key: string, updatedMetaTag: MetaTag) => {
		normalizationVisitor.visitMetaTagNode(updatedMetaTag);
		setMetaTags((prevMetaTags) => prevMetaTags.map((e) => (e.key === key ? updatedMetaTag : e)));
	}, []);

	const deleteMetaTag = React.useCallback((key: string) => {
		setMetaTags((prevMetaTags) => prevMetaTags.filter((e) => e.key !== key));
	}, []);

	return {
		schema,
		models,
		edges,
		metaTags,
		acceptSchema,
		createModel,
		updateModel,
		deleteModel,
		createEdge,
		updateEdge,
		deleteEdge,
		createMetaTag,
		updateMetaTag,
		deleteMetaTag,
	};
}
