import { ClassNameProps, makeClassName } from "@hex-insights/core";
import styles from "./styles.module.css";

export type IconProps = Partial<ClassNameProps & { size: number }>;

export function ClipboardIcon({ size = 24, className }: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="transparent"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={makeClassName(className, styles["icon"])}
		>
			<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
			<rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
		</svg>
	);
}

export function ChevronRight({ size = 24, className }: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={makeClassName(className, styles["icon"])}
		>
			<polyline points="9 18 15 12 9 6"></polyline>
		</svg>
	);
}

export function ChevronDown({ size = 24, className }: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={makeClassName(className, styles["icon"])}
		>
			<polyline points="6 9 12 15 18 9"></polyline>
		</svg>
	);
}

export function EditIcon({ size = 24, className }: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="transparent"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={makeClassName(className, styles["icon"])}
		>
			<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
			<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
		</svg>
	);
}

export function Plus({ size = 24, className }: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={makeClassName(className, styles["icon"])}
		>
			<line x1="12" y1="5" x2="12" y2="19"></line>
			<line x1="5" y1="12" x2="19" y2="12"></line>
		</svg>
	);
}
