import React from "react";
import { Button, Column, Heading, Modal, Row } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";
import {
	containsEdge,
	CreateFunction,
	DeleteFunction,
	Edge,
	EdgeFormValues,
	Model,
	UpdateFunction,
} from "../../Utilities";
import { EdgeList } from "../EdgeList";
import { EdgeForm } from "../Forms";

export type EdgesEditorProps = {
	models: Model[];
	edges: Edge[];
	create: CreateFunction<Edge>;
	update: UpdateFunction<Edge>;
	del: DeleteFunction;
};

export function EdgesEditor({ models, edges, create, update, del }: EdgesEditorProps) {
	return (
		<Column align="start" justify="start">
			<EdgeList edges={edges} models={models} update={update} del={del} />

			<EdgeCreator models={models} edges={edges} create={create} />
		</Column>
	);
}

type EdgeCreatorProps = {
	models: Model[];
	edges: Edge[];
	create: CreateFunction<Edge>;
};

function EdgeCreator({ models, edges, create }: EdgeCreatorProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const validate = React.useCallback(
		(formValues: EdgeFormValues) => {
			if (containsEdge(edges, formValues)) {
				return invalid("Edge already exists");
			}
			return valid();
		},
		[edges],
	);

	return (
		<React.Fragment>
			<Row justify="center" style={{ width: "100%" }}>
				<Button variant="primary" onClick={toggleIsModalOpen}>
					Add New Edge
				</Button>
			</Row>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>New Edge</Heading.H2>
					</Modal.Header>

					<Modal.Body>
						<EdgeForm.ControlledCreate
							models={models}
							validate={validate}
							create={create}
							onCancel={toggleIsModalOpen}
							onSuccess={toggleIsModalOpen}
						/>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
