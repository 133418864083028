import { MetaTagNode } from "@hex-insights/srsl";
import { MetaTag } from "../../types";
import { MetaTagFormValues } from "../FormValues";

export function metaTagFormValuesToMetaTag(metaTagFormValues: MetaTagFormValues, key?: string) {
	return new MetaTag(metaTagFormValues.tag, metaTagFormValues.value, key);
}

export function metaTagNodeToMetaTagFormValues(metaTag: MetaTagNode): MetaTagFormValues {
	return {
		tag: metaTag.tag,
		value: metaTag.value,
	};
}
