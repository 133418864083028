import React from "react";
import ReactDOM from "react-dom/client";
import {
	EscapeListenerManager,
	GlobalStackingManager,
	LocalStorageManager,
	NotificationManager,
	ScrollLockManager,
} from "@hex-insights/core";
import { BrowserRouter } from "@hex-insights/router";
import "@hex-insights/core/dist/index.css";
import "@hex-insights/forms/dist/index.css";
import "@hex-insights/srsl/dist/index.css";
import { App } from "./App";
import { VersionController } from "./Components";
import "./theme.css";

export function Root() {
	return (
		<React.StrictMode>
			<GlobalStackingManager>
				<LocalStorageManager>
					<BrowserRouter>
						<ScrollLockManager>
							<EscapeListenerManager>
								<NotificationManager>
									<App />
									<VersionController />
								</NotificationManager>
							</EscapeListenerManager>
						</ScrollLockManager>
					</BrowserRouter>
				</LocalStorageManager>
			</GlobalStackingManager>
		</React.StrictMode>
	);
}

const root = ReactDOM.createRoot(document.querySelector("#root")!);
root.render(<Root />);
