import React from "react";
import { ChildrenProps, ClassNameProps, List, Row } from "@hex-insights/core";
import { SchemaNode, ToReactVisitor, ValidationVisitor } from "@hex-insights/srsl";
import { reactHTMLCodeToString } from "../../Utilities";
import { ClickToCopy } from "../ClickToCopy";
import { RawEditorButton } from "../RawEditor";
import "./style.css";
import styles from "./styles.module.css";

const validationVisitor = new ValidationVisitor();
const toReactVisitor = new ToReactVisitor();

export type HighlightProps = {
	schema: SchemaNode;
	onOpenRawEditor: () => void;
} & Partial<ClassNameProps>;

export function Highlight({ schema, onOpenRawEditor, className }: HighlightProps) {
	const [warning, setWarnings] = React.useState<string[]>([]);
	const [lineNumbers, setLineNumbers] = React.useState<React.DetailedReactHTMLElement<
		Partial<ClassNameProps> & ChildrenProps,
		HTMLSpanElement | HTMLBRElement
	> | null>(null);
	const [reconstructed, setReconstructed] = React.useState<
		React.DetailedReactHTMLElement<ClassNameProps & Partial<ChildrenProps>, HTMLSpanElement | HTMLBRElement>[]
	>([]);

	React.useEffect(() => {
		toReactVisitor.reset();
		schema.accept(validationVisitor);
		schema.accept(toReactVisitor);
		setWarnings(toReactVisitor.getWarnings());
		setLineNumbers(toReactVisitor.getLineNumberGutter(styles["line-numbers"]));
		setReconstructed(toReactVisitor.getOutput());
	}, [schema]);

	const getCodeValue = React.useCallback(() => {
		return reactHTMLCodeToString(reconstructed);
	}, [reconstructed]);

	return (
		<div className={className}>
			<List>
				{warning.map((warning) => (
					<List.Item key={warning}>
						<code>{warning}</code>
					</List.Item>
				))}
			</List>
			<pre className={styles["code-block-container"]}>
				<Row className={styles["toolbar"]}>
					<RawEditorButton onClick={onOpenRawEditor} />
					<ClickToCopy
						className={styles["click-to-copy-button"]}
						getValue={getCodeValue}
						disabled={schema.models.length === 0}
					/>
				</Row>

				{lineNumbers}
				<code className={styles["code-block"]}>{reconstructed}</code>
			</pre>
		</div>
	);
}
