import {
	addQuotes,
	EdgeNode,
	EdgeType,
	NamedAttributeNode,
	NamedPropertyListNode,
	PlainPropertyValueNode,
	PropertyNode,
	removeQuotes,
	StringLiteralNode,
} from "@hex-insights/srsl";
import { getDefaultSourceAlias, getDefaultTargetAlias } from "../../edge-alias";
import { findNamedPropertyNodeList } from "../../property-finder";
import { Edge } from "../../types";
import {
	ConvenientEdgeType,
	CreateFormFieldType,
	DetailFormFieldType,
	EdgeFormValues,
	EdgePropertyFormValues,
} from "../FormValues";

export function edgeFormValuesToEdge(formValues: EdgeFormValues, key?: string): Edge {
	const { source, sourceAlias, edgeType, target, targetAlias, ...propertyFormValues } = formValues;

	if (edgeType === ConvenientEdgeType.ManyToOne) {
		return edgeFormValuesToEdge({
			source: target,
			sourceAlias: targetAlias,
			edgeType: ConvenientEdgeType.OneToMany,
			target: source,
			targetAlias: sourceAlias,
			...flipEdgeSideFormValueProperties(propertyFormValues),
		});
	}

	const sourceAliasNode = new StringLiteralNode(addQuotes(getSourceAlias(source, sourceAlias, edgeType)));
	const targetAliasNode = new StringLiteralNode(addQuotes(getTargetAlias(target, targetAlias, edgeType)));

	const namedAttributes: NamedAttributeNode[] = [];
	const { properties, leftProperties, rightProperties } =
		edgePropertyFormValuesToEdgePropertyNodeList(propertyFormValues);
	if (properties.length > 0) {
		namedAttributes.push(new NamedPropertyListNode("@properties", properties));
	}
	if (leftProperties.length > 0) {
		namedAttributes.push(new NamedPropertyListNode("@left_properties", leftProperties));
	}
	if (rightProperties.length > 0) {
		namedAttributes.push(new NamedPropertyListNode("@right_properties", rightProperties));
	}

	let finalEdgeType: EdgeType;

	switch (edgeType) {
		case ConvenientEdgeType.OneToOne:
			finalEdgeType = EdgeType.OneToOne;
			break;
		case ConvenientEdgeType.ManyToMany:
			finalEdgeType = EdgeType.ManyToMany;
			break;
		case ConvenientEdgeType.OneToMany:
			finalEdgeType = EdgeType.OneToMany;
			break;
	}

	return new Edge(source, sourceAliasNode, finalEdgeType, target, targetAliasNode, namedAttributes, key);
}

function edgePropertyFormValuesToEdgePropertyNodeList(formValues: EdgePropertyFormValues) {
	const properties: PropertyNode[] = [];
	if (formValues.hasPropertyAPIPrivate) {
		properties.push(new PropertyNode("APIPrivate", []));
	}
	if (formValues.hasPropertyAPIReadOnly) {
		properties.push(new PropertyNode("APIReadOnly", []));
	}
	if (formValues.hasPropertyImmutable) {
		properties.push(new PropertyNode("Immutable", []));
	}
	if (formValues.hasPropertyOnDelete) {
		properties.push(new PropertyNode("OnDelete", [new PlainPropertyValueNode(formValues.propertyValueOnDelete)]));
	}

	const leftProperties: PropertyNode[] = [];
	if (formValues.leftHasPropertyAPIUpdateOnly) {
		leftProperties.push(new PropertyNode("APIUpdateOnly", []));
	}
	if (formValues.leftPropertyValueCreateFormFieldType) {
		leftProperties.push(
			new PropertyNode("CreateFormFieldType", [
				new PlainPropertyValueNode(formValues.leftPropertyValueCreateFormFieldType),
			]),
		);
	}
	if (formValues.leftPropertyValueDetailFormFieldType) {
		leftProperties.push(
			new PropertyNode("DetailFormFieldType", [
				new PlainPropertyValueNode(formValues.leftPropertyValueDetailFormFieldType),
			]),
		);
	}
	if (formValues.leftHasPropertyRequired) {
		leftProperties.push(new PropertyNode("Required", []));
	}
	if (formValues.leftHasPropertySearchable) {
		leftProperties.push(new PropertyNode("Searchable", []));
	}

	const rightProperties: PropertyNode[] = [];
	if (formValues.rightHasPropertyAPIUpdateOnly) {
		rightProperties.push(new PropertyNode("APIUpdateOnly", []));
	}
	if (formValues.rightPropertyValueCreateFormFieldType) {
		rightProperties.push(
			new PropertyNode("CreateFormFieldType", [
				new PlainPropertyValueNode(formValues.rightPropertyValueCreateFormFieldType),
			]),
		);
	}
	if (formValues.rightPropertyValueDetailFormFieldType) {
		rightProperties.push(
			new PropertyNode("DetailFormFieldType", [
				new PlainPropertyValueNode(formValues.rightPropertyValueDetailFormFieldType),
			]),
		);
	}
	if (formValues.rightHasPropertyRequired) {
		rightProperties.push(new PropertyNode("Required", []));
	}
	if (formValues.rightHasPropertySearchable) {
		rightProperties.push(new PropertyNode("Searchable", []));
	}

	return { properties, leftProperties, rightProperties };
}

function flipEdgeSideFormValueProperties(formValues: EdgePropertyFormValues): EdgePropertyFormValues {
	return {
		...formValues,
		leftHasPropertyAPIUpdateOnly: formValues.rightHasPropertyAPIUpdateOnly,
		leftHasPropertyRequired: formValues.rightHasPropertyRequired,
		leftHasPropertySearchable: formValues.rightHasPropertySearchable,
		leftPropertyValueCreateFormFieldType: formValues.rightPropertyValueCreateFormFieldType,
		leftPropertyValueDetailFormFieldType: formValues.rightPropertyValueDetailFormFieldType,
		rightHasPropertyAPIUpdateOnly: formValues.leftHasPropertyAPIUpdateOnly,
		rightHasPropertyRequired: formValues.leftHasPropertyRequired,
		rightHasPropertySearchable: formValues.leftHasPropertySearchable,
		rightPropertyValueCreateFormFieldType: formValues.leftPropertyValueCreateFormFieldType,
		rightPropertyValueDetailFormFieldType: formValues.leftPropertyValueDetailFormFieldType,
	};
}

function getSourceAlias(source: string, sourceAlias: string, edgeType: ConvenientEdgeType) {
	return sourceAlias || getDefaultSourceAlias(source, edgeType);
}

function getTargetAlias(target: string, targetAlias: string, edgeType: ConvenientEdgeType) {
	return targetAlias || getDefaultTargetAlias(target, edgeType);
}

export function edgeNodeToEdgeFormValues(edge: EdgeNode): EdgeFormValues {
	let finalEdgeType: ConvenientEdgeType;
	switch (edge.edgeType) {
		case EdgeType.OneToOne:
			finalEdgeType = ConvenientEdgeType.OneToOne;
			break;
		case EdgeType.ManyToMany:
			finalEdgeType = ConvenientEdgeType.ManyToMany;
			break;
		case EdgeType.OneToMany:
			finalEdgeType = ConvenientEdgeType.OneToMany;
			break;
	}

	const properties = edgePropertyNodeListToEdgePropertyFormValues(edge.namedAttributes);

	return {
		source: edge.source,
		sourceAlias: removeQuotes(edge.sourceAlias.value),
		edgeType: finalEdgeType,
		target: edge.target,
		targetAlias: removeQuotes(edge.targetAlias.value),
		...properties,
	};
}

function edgePropertyNodeListToEdgePropertyFormValues(namedAttributes: NamedAttributeNode[]) {
	const formValues: EdgePropertyFormValues = {
		hasPropertyAPIPrivate: false,
		hasPropertyAPIReadOnly: false,
		hasPropertyImmutable: false,
		hasPropertyOnDelete: false,
		propertyValueOnDelete: "",
		leftHasPropertyAPIUpdateOnly: false,
		leftHasPropertyRequired: false,
		leftHasPropertySearchable: false,
		leftPropertyValueCreateFormFieldType: null,
		leftPropertyValueDetailFormFieldType: null,
		rightHasPropertyAPIUpdateOnly: false,
		rightHasPropertyRequired: false,
		rightHasPropertySearchable: false,
		rightPropertyValueCreateFormFieldType: null,
		rightPropertyValueDetailFormFieldType: null,
	};

	const propertyNodes = findNamedPropertyNodeList("@properties", namedAttributes);
	for (let i = 0; i < propertyNodes.length; i++) {
		const property = propertyNodes[i];
		switch (property.property) {
			case "APIPrivate":
				formValues.hasPropertyAPIPrivate = true;
				break;
			case "APIReadOnly":
				formValues.hasPropertyAPIReadOnly = true;
				break;
			case "Immutable":
				formValues.hasPropertyImmutable = true;
				break;
			case "OnDelete":
				formValues.hasPropertyOnDelete = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueOnDelete = property.propertyValues[0].value;
				}
				break;
		}
	}

	const leftPropertyNodes = findNamedPropertyNodeList("@left_properties", namedAttributes);
	for (let i = 0; i < leftPropertyNodes.length; i++) {
		const node = leftPropertyNodes[i];
		switch (node.property) {
			case "APIUpdateOnly":
				formValues.leftHasPropertyAPIUpdateOnly = true;
				break;
			case "CreateFormFieldType":
				if (node.propertyValues.length > 0) {
					formValues.leftPropertyValueCreateFormFieldType = node.propertyValues[0].value as CreateFormFieldType;
				} else {
					formValues.leftPropertyValueCreateFormFieldType = null;
				}
				break;
			case "DetailFormFieldType":
				if (node.propertyValues.length > 0) {
					formValues.leftPropertyValueDetailFormFieldType = node.propertyValues[0].value as DetailFormFieldType;
				} else {
					formValues.leftPropertyValueDetailFormFieldType = null;
				}
				break;
			case "Required":
				formValues.leftHasPropertyRequired = true;
				break;
			case "Searchable":
				formValues.leftHasPropertySearchable = true;
				break;
		}
	}

	const rightPropertyNodes = findNamedPropertyNodeList("@right_properties", namedAttributes);
	for (let i = 0; i < rightPropertyNodes.length; i++) {
		const node = rightPropertyNodes[i];
		switch (node.property) {
			case "APIUpdateOnly":
				formValues.rightHasPropertyAPIUpdateOnly = true;
				break;
			case "CreateFormFieldType":
				if (node.propertyValues.length > 0) {
					formValues.rightPropertyValueCreateFormFieldType = node.propertyValues[0].value as CreateFormFieldType;
				} else {
					formValues.rightPropertyValueCreateFormFieldType = null;
				}
				break;
			case "DetailFormFieldType":
				if (node.propertyValues.length > 0) {
					formValues.rightPropertyValueDetailFormFieldType = node.propertyValues[0].value as DetailFormFieldType;
				} else {
					formValues.rightPropertyValueDetailFormFieldType = null;
				}
				break;
			case "Required":
				formValues.rightHasPropertyRequired = true;
				break;
			case "Searchable":
				formValues.rightHasPropertySearchable = true;
				break;
		}
	}

	return formValues;
}
