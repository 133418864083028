import React from "react";
import { Button, ButtonWithConfirmation, Collapsible, Column, Heading, If, Row } from "@hex-insights/core";
import {
	AddSubFormButtonRenderProps,
	BooleanField,
	composeSyncValidationFunctions,
	EditMode,
	FieldIf,
	Form,
	FormState,
	FormStateWithoutSubmissionState,
	FormType,
	NumberField,
	SelectField,
	SubFormField,
	SubFormRenderProps,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	SyncValidationFunction,
	TextField,
	useFormState,
	useOnSuccess,
	ValidationResult,
	ValidationStatus,
} from "@hex-insights/forms";
import { supportedValues } from "@hex-insights/srsl";
import {
	blankFieldFormValues,
	blankModelFormValues,
	blankTypeValueFormValues,
	countActiveFieldProperties,
	countActiveModelProperties,
	CreateFunction,
	DeleteFunction,
	FieldFormValues,
	FieldPropertyFormValues,
	keywordValidation,
	Model,
	ModelFormValues,
	modelFormValuesToModel,
	modelNodeToModelFormValues,
	pascalCaseValidation,
	snakeCaseValidation,
	TypeValue,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../../Utilities";
import { AddButton, DeleteButton, HideItems, ShowItems } from "../Buttons";
import styles from "../form-styles.module.css";

type SharedProps = {
	onCancel: () => void;
	onSuccess: () => void;
};

export type ControlledCreateProps = Omit<CreateProps, "formState">;

export function ControlledCreate({ validate, create, onCancel, onSuccess }: ControlledCreateProps) {
	const formState = useFormState({ initialFormValues: blankModelFormValues, formType: FormType.Create });

	return <Create formState={formState} validate={validate} create={create} onCancel={onCancel} onSuccess={onSuccess} />;
}

export type CreateProps = SharedProps & {
	formState: FormState<ModelFormValues>;
	validate: SyncValidationFunction<ModelFormValues>;
	create: CreateFunction<Model>;
};

export function Create({ formState, validate, create, onCancel, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<ModelFormValues>) => {
			const validity = validate(formState.formValues);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<ModelFormValues>({
					name: { _: validity.errors },
				});
			}

			create(modelFormValuesToModel(formState.formValues));
			return submissionSuccess();
		},
		[validate, create],
	);

	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<FormBody formState={formState} autoFocus />

			<Row className={styles["form-submit-buttons"]} justify="space-between">
				<Button
					variant="secondary"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onCancel}
				>
					Cancel
				</Button>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Create Model
				</SubmitButton>
			</Row>
		</Form>
	);
}

export type ControlledUpdateProps = Omit<UpdateProps, "formState">;

export function ControlledUpdate({ model, validate, update, del, onCancel, onSuccess }: ControlledUpdateProps) {
	const formState = useFormState({
		initialFormValues: modelNodeToModelFormValues(model),
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});

	return (
		<Update
			formState={formState}
			model={model}
			validate={validate}
			update={update}
			del={del}
			onCancel={onCancel}
			onSuccess={onSuccess}
		/>
	);
}

export type UpdateProps = SharedProps & {
	formState: FormState<ModelFormValues>;
	model: Model;
	validate: UpdateValidationFunction<ModelFormValues>;
	update: UpdateFunction<Model>;
	del: DeleteFunction;
};

export function Update({ formState, model, validate, del, update, onCancel, onSuccess }: UpdateProps) {
	const modelKey = model.key;

	const onDeleteClick = React.useCallback(() => {
		del(modelKey);
	}, [del, modelKey]);

	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<ModelFormValues>) => {
			const validity = validate(modelKey, formState.formValues);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<ModelFormValues>({
					name: { _: validity.errors },
				});
			}
			update(modelKey, modelFormValuesToModel(formState.formValues, modelKey));
			return submissionSuccess();
		},
		[modelKey, validate, update],
	);

	console.log(formState.submissionStatus);

	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<FormBody formState={formState} />

			<Row className={styles["form-submit-buttons"]} justify="space-between">
				<Button
					variant="secondary"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onCancel}
				>
					Cancel
				</Button>

				<ButtonWithConfirmation
					variant="danger"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onDeleteClick}
					confirmationContent="Are you sure you want to delete the model?"
				>
					Delete
				</ButtonWithConfirmation>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Update Model
				</SubmitButton>
			</Row>
		</Form>
	);
}

type FormBodyProps = {
	formState: FormState<ModelFormValues>;
	autoFocus?: boolean;
};

function FormBody({ formState, autoFocus }: FormBodyProps) {
	return (
		<Column justify="spaced-start">
			<TextField
				formState={formState}
				name="name"
				immediateValidation={pascalCaseValidation}
				hint="A PascalCased name"
				autoFocus={autoFocus}
			/>

			<TextField formState={formState} name="description" optional multiLine />

			<TextField formState={formState} name="tableName" label="Custom Table Name" optional />

			<Collapsible
				className={styles["collapsible-field-set"]}
				buttonVariant="secondary"
				buttonSize="small"
				expandButtonContent={
					<ShowItems name="Model Properties" numItems={countActiveModelProperties(formState.formValues)} />
				}
				collapseButtonContent={<HideItems name="Model Properties" />}
			>
				<Column justify="spaced-start">
					<BooleanField
						formState={formState}
						name="hasPropertyAPIImmutable"
						label="API Immutable"
						hint="Disallows update actions on instances through the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIPrivate"
						label="API Private"
						hint="Hides the model from the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIReadOnly"
						label="API Read-Only"
						hint="Disallows create, update, and delete actions on instances through the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIUpdateOnly"
						label="API Update-Only"
						hint="Disallows create and delete actions on instances through the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyNoGlobalSearch"
						label="No Global Search"
						hint="Excludes instances of the model from global search results."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyNoHistory"
						label="No History"
						hint="Disables history tracking on instances of the model."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyNoPermissions"
						label="No Permissions"
						hint="Prevents permissions from being generated or required for the model."
					/>
				</Column>
			</Collapsible>

			<Collapsible
				className={styles["collapsible-field-set"]}
				buttonVariant="secondary"
				buttonSize="small"
				expandButtonContent={<ShowItems name="Fields" numItems={formState.formValues.fields.length} />}
				collapseButtonContent={<HideItems name="Fields" />}
			>
				<SubFormField<ModelFormValues, FieldFormValues>
					formState={formState}
					name="fields"
					blankItem={blankFieldFormValues}
					dividerElement={<hr style={{ margin: "1rem 0" }} />}
				>
					{FieldFormBody}
					{AddFieldButton}
				</SubFormField>
			</Collapsible>
		</Column>
	);
}

const typeOptions = supportedValues.modelFieldTypes.map((value) => ({ value }));

const fieldNameValidation = composeSyncValidationFunctions([snakeCaseValidation, keywordValidation]);

const propertyRangeValueFieldNames: (keyof FieldPropertyFormValues)[] = [
	"propertyValueRangeMin",
	"propertyValueRangeMax",
];

const noteNoAutomaticFormatting =
	"Note: This value will not have formatting automatically applied, quotes or codeblock ticks must be added manually.";

function FieldFormBody({ formState, onRemoveClick }: SubFormRenderProps<FieldFormValues>) {
	const { setFieldValue, setFieldValidationStatus } = formState;

	const fieldRequiresTypeValues = formState.formValues.type === "Enum";
	const fieldAllowsPropertyUnique = formState.formValues.type !== "Enum";
	const fieldIsNumber = formState.formValues.type === "Int" || formState.formValues.type === "Float";
	const fieldIsNumeric = fieldIsNumber || formState.formValues.type === "Time" || formState.formValues.type === "Date";
	const fieldIsString = formState.formValues.type === "String";
	const fieldHasLength = fieldIsString;

	React.useEffect(() => {
		if (fieldRequiresTypeValues) {
			return;
		} else {
			setFieldValidationStatus("typeValues", ValidationStatus.Success);
			setFieldValue("typeValues", [] as TypeValue[]);
		}
	}, [fieldRequiresTypeValues, setFieldValue, setFieldValidationStatus]);

	return (
		<Column justify="spaced-center" align="start">
			<DeleteButton name="Field" onClick={onRemoveClick} />

			<TextField
				formState={formState}
				name="name"
				label="Field Name"
				immediateValidation={fieldNameValidation}
				hint="A snake_cased field name."
				autoFocus
			/>

			<SelectField formState={formState} name="type" options={typeOptions} />

			{fieldRequiresTypeValues && (
				<Collapsible
					className={styles["collapsible-field-set"]}
					buttonVariant="secondary"
					buttonSize="small"
					expandButtonContent={<ShowItems name="Type Values" numItems={formState.formValues.typeValues.length} />}
					collapseButtonContent={<HideItems name="TypeValues" />}
				>
					<SubFormField<FieldFormValues, TypeValue>
						formState={formState}
						name="typeValues"
						blankItem={blankTypeValueFormValues}
						minItems={1}
					>
						{TypeValueFormBody}
						{AddTypeValueButton}
					</SubFormField>
				</Collapsible>
			)}

			<Collapsible
				className={styles["collapsible-field-set"]}
				buttonVariant="secondary"
				buttonSize="small"
				expandButtonContent={
					<ShowItems name="Field Properties" numItems={countActiveFieldProperties(formState.formValues)} />
				}
				collapseButtonContent={<HideItems name="Field Properties" />}
			>
				<Heading.H4>General Properties</Heading.H4>

				<Column justify="spaced-start">
					<BooleanField
						formState={formState}
						name="hasPropertyAPIPrivate"
						label="API Private"
						hint="Hides the field from the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIReadOnly"
						label="API Read-Only"
						hint="Disallows setting or updating the field through the API."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIUpdateOnly"
						label="API Update-Only"
						hint="Disallows setting a value for the field when creating an instance of the model through the API. In other words, the initial value is set automatically by the server."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAPIComputed"
						label="API Computed"
						hint="Indicates the field's value is computed in the API layer of the server. Useful for properties that depend on the viewer (user). For general computed fields, see the Computed option."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyAnnotations"
						label="Annotations"
						hint="Sets custom annotations on the field's ent schema definition."
					/>
					<FieldIf
						formState={formState}
						name="propertyValueAnnotations"
						condition={formState.formValues.hasPropertyAnnotations}
					>
						<TextField formState={formState} name="propertyValueAnnotations" label="Annotations" />
					</FieldIf>

					<BooleanField
						formState={formState}
						name="hasPropertyComputed"
						label="Computed"
						hint="Indicates the field's value is computed on the server rather than stored in the database."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyDefault"
						label="Default"
						hint="Sets a value for the field."
					/>
					<FieldIf
						formState={formState}
						name="propertyValueDefault"
						condition={formState.formValues.hasPropertyDefault}
					>
						<TextField
							formState={formState}
							name="propertyValueDefault"
							label="Default"
							hint={
								"The default value for the field. Can be a value or a function that returns a value.\n\n" +
								noteNoAutomaticFormatting
							}
						/>
					</FieldIf>

					<BooleanField
						formState={formState}
						name="hasPropertyImmutable"
						label="Immutable"
						hint="Prevents updates to the field after creation."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyNillable"
						label="Nillable"
						hint="Allows nil (null) values for this field."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyOptional"
						label="Optional"
						hint="Removes requirement to give a value for the field when creating a new instance of the model."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyOrderField"
						label="Order Field"
						hint="Allows ordering instances of the model on the field through the API."
					/>

					<FieldIf
						formState={formState}
						name="hasPropertyDefaultOrderField"
						condition={formState.formValues.hasPropertyOrderField}
					>
						<BooleanField
							formState={formState}
							name="hasPropertyDefaultOrderField"
							label="Default Order Field"
							hint="Sets the default order of the model to this field. Only necessary if customization is desired. Note that if multiple fields have this property, the first field will be used."
						/>
					</FieldIf>

					<BooleanField
						formState={formState}
						name="hasPropertySensitive"
						label="Sensitive"
						hint="Prevents the field from being printed or encoded."
					/>

					<BooleanField
						formState={formState}
						name="hasPropertyStructTag"
						label="Struct Tag"
						hint="Sets a custom struct tag for the field."
					/>
					<FieldIf
						formState={formState}
						name="propertyValueStructTag"
						condition={formState.formValues.hasPropertyStructTag}
					>
						<TextField formState={formState} name="propertyValueStructTag" label="Struct Tag" />
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyUnique" condition={fieldAllowsPropertyUnique}>
						<BooleanField
							formState={formState}
							name="hasPropertyUnique"
							label="Unique"
							hint="Requires each instance of the model to have a distinct value for the field."
						/>
					</FieldIf>

					<BooleanField
						formState={formState}
						name="hasPropertyValidate"
						label="Validate"
						hint="Sets a custom validation function for the field."
					/>
					<FieldIf
						formState={formState}
						name="propertyValueValidate"
						condition={formState.formValues.hasPropertyValidate}
					>
						<TextField formState={formState} name="propertyValueValidate" label="Validate" />
					</FieldIf>
				</Column>

				<If condition={fieldHasLength}>
					<Heading.H4>Length Properties</Heading.H4>
				</If>

				<Column justify="spaced-start">
					<FieldIf formState={formState} name="hasPropertyAllowsEmpty" condition={fieldHasLength}>
						<BooleanField
							formState={formState}
							name="hasPropertyAllowsEmpty"
							label="Allows Empty"
							hint="Allows empty strings."
						/>
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyMinLen" condition={fieldHasLength}>
						<BooleanField
							formState={formState}
							name="hasPropertyMinLen"
							label="Min Length"
							hint="Sets a minimum length."
						/>
					</FieldIf>
					<FieldIf formState={formState} name="propertyValueMinLen" condition={formState.formValues.hasPropertyMinLen}>
						<NumberField formState={formState} name="propertyValueMinLen" label="Min Length" validationUnit={1} />
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyMaxLen" condition={fieldHasLength}>
						<BooleanField
							formState={formState}
							name="hasPropertyMaxLen"
							label="Max Length"
							hint="Sets a maximum length."
						/>
					</FieldIf>
					<FieldIf formState={formState} name="propertyValueMaxLen" condition={formState.formValues.hasPropertyMaxLen}>
						<NumberField formState={formState} name="propertyValueMaxLen" label="Max Length" validationUnit={1} />
					</FieldIf>
				</Column>

				<If condition={fieldIsString}>
					<Heading.H4>String Properties</Heading.H4>
				</If>

				<Column justify="spaced-start">
					<FieldIf formState={formState} name="hasPropertyMatch" condition={fieldIsString}>
						<BooleanField
							formState={formState}
							name="hasPropertyMatch"
							label="Match"
							hint="Sets a custom pattern that values of the field must match."
						/>
					</FieldIf>
					<FieldIf formState={formState} name="propertyValueMatch" condition={formState.formValues.hasPropertyMatch}>
						<TextField
							formState={formState}
							name="propertyValueMatch"
							label="Match"
							hint="A Go code snippet that evaulates to a regular expression."
						/>
					</FieldIf>
				</Column>

				<If condition={fieldIsNumber || fieldIsNumeric}>
					<Heading.H4>Numeric Properties</Heading.H4>
				</If>

				<Column justify="spaced-start">
					<FieldIf formState={formState} name="hasPropertyPositive" condition={fieldIsNumber}>
						<BooleanField
							formState={formState}
							name="hasPropertyPositive"
							label="Positive"
							hint="Requires values of the field be positive."
						/>
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyNegative" condition={fieldIsNumber}>
						<BooleanField
							formState={formState}
							name="hasPropertyNegative"
							label="Negative"
							hint="Requires values of the field be negative."
						/>
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyNonNegative" condition={fieldIsNumber}>
						<BooleanField
							formState={formState}
							name="hasPropertyNonNegative"
							label="Non-Negative"
							hint="Requires values of the field be non-negative."
						/>
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyMin" condition={fieldIsNumeric}>
						<BooleanField
							formState={formState}
							name="hasPropertyMin"
							label="Min"
							hint="Sets a minimum allowed value for the field."
						/>
					</FieldIf>
					<FieldIf formState={formState} name="propertyValueMin" condition={formState.formValues.hasPropertyMin}>
						<TextField formState={formState} name="propertyValueMin" label="Min" hint={noteNoAutomaticFormatting} />
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyMax" condition={fieldIsNumeric}>
						<BooleanField
							formState={formState}
							name="hasPropertyMax"
							label="Max"
							hint="Sets a maximum allowed value for the field."
						/>
					</FieldIf>
					<FieldIf formState={formState} name="propertyValueMax" condition={formState.formValues.hasPropertyMax}>
						<TextField formState={formState} name="propertyValueMax" label="Max" hint={noteNoAutomaticFormatting} />
					</FieldIf>

					<FieldIf formState={formState} name="hasPropertyRange" condition={fieldIsNumeric}>
						<BooleanField
							formState={formState}
							name="hasPropertyRange"
							label="Range"
							hint="Sets minimum and maximum allowed values for the field."
						/>
					</FieldIf>
					<FieldIf
						formState={formState}
						names={propertyRangeValueFieldNames}
						condition={formState.formValues.hasPropertyRange}
					>
						<TextField
							formState={formState}
							name="propertyValueRangeMin"
							label="Min"
							hint={noteNoAutomaticFormatting}
						/>
						<TextField
							formState={formState}
							name="propertyValueRangeMax"
							label="Max"
							hint={noteNoAutomaticFormatting}
						/>
					</FieldIf>
				</Column>
			</Collapsible>
		</Column>
	);
}

function AddFieldButton(props: AddSubFormButtonRenderProps) {
	return <AddButton name="Field" {...props} />;
}

function TypeValueFormBody({ formState, onRemoveClick }: SubFormRenderProps<TypeValue>) {
	return (
		<Row className={styles["row-with-bottom-margin"]} justify="spaced-center" align="end">
			<TextField
				formState={formState}
				name="value"
				immediateValidation={snakeCaseValidation}
				hint="A snake_cased type value"
				autoFocus
			/>

			<DeleteButton onClick={onRemoveClick} />
		</Row>
	);
}

function AddTypeValueButton(props: AddSubFormButtonRenderProps) {
	return <AddButton name="Enum Value" {...props} />;
}
