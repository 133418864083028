import React from "react";
import { ChildrenProps, ClassNameProps } from "@hex-insights/core";
import { SchemaNode, ToReactVisitor } from "@hex-insights/srsl";

export function schemaToCode(schema: SchemaNode) {
	const toReactVisitor = new ToReactVisitor();
	schema.accept(toReactVisitor);
	const reconstructed = toReactVisitor.getOutput();
	return reactHTMLCodeToString(reconstructed);
}

export function reactHTMLCodeToString(
	reconstructed: React.DetailedReactHTMLElement<
		ClassNameProps & Partial<ChildrenProps>,
		HTMLSpanElement | HTMLBRElement
	>[],
) {
	return reconstructed
		.map((node) => {
			switch (node.type) {
				case "br":
					return "\n";
				case "span":
					if (typeof node.props.children === "string") {
						return node.props.children;
					}
					return "";
				default:
					return "";
			}
		})
		.join("");
}
