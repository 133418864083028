import {
	addQuotes,
	FieldNode,
	ModelNode,
	NamedAttributeNode,
	NamedPropertyListNode,
	NamedStringAttributeNode,
	PropertyNode,
	StringLiteralNode,
} from "@hex-insights/srsl";
import { findNamedPropertyNodeList, findNamedStringProperty } from "../../property-finder";
import { Model } from "../../types";
import { ModelFormValues, ModelPropertyFormValues } from "../FormValues";
import { fieldFormValuesToFieldNode, fieldNodeToFieldFormValues } from "./field";

export function modelFormValuesToModel(formValues: ModelFormValues, key?: string) {
	const namedAttributes: NamedAttributeNode[] = [];
	if (formValues.description) {
		namedAttributes.push(
			new NamedStringAttributeNode("@description", new StringLiteralNode(addQuotes(formValues.description))),
		);
	}

	const properties = modelPropertyFormValuesToModelPropertyNodeList(formValues);
	if (properties.length > 0) {
		namedAttributes.push(new NamedPropertyListNode("@properties", properties));
	}

	if (formValues.tableName) {
		namedAttributes.push(
			new NamedStringAttributeNode("@table_name", new StringLiteralNode(addQuotes(formValues.tableName))),
		);
	}
	const fields: FieldNode[] = formValues.fields.map(fieldFormValuesToFieldNode);

	return new Model(formValues.name, namedAttributes, fields, key);
}

function modelPropertyFormValuesToModelPropertyNodeList(formValues: ModelPropertyFormValues) {
	const properties: PropertyNode[] = [];
	if (formValues.hasPropertyAPIImmutable) {
		properties.push(new PropertyNode("APIImmutable", []));
	}
	if (formValues.hasPropertyAPIPrivate) {
		properties.push(new PropertyNode("APIPrivate", []));
	}
	if (formValues.hasPropertyAPIReadOnly) {
		properties.push(new PropertyNode("APIReadOnly", []));
	}
	if (formValues.hasPropertyAPIUpdateOnly) {
		properties.push(new PropertyNode("APIUpdateOnly", []));
	}
	if (formValues.hasPropertyNoGlobalSearch) {
		properties.push(new PropertyNode("NoGlobalSearch", []));
	}
	if (formValues.hasPropertyNoHistory) {
		properties.push(new PropertyNode("NoHistory", []));
	}
	if (formValues.hasPropertyNoPermissions) {
		properties.push(new PropertyNode("NoPermissions", []));
	}
	return properties;
}

export function modelNodeToModelFormValues(model: ModelNode): ModelFormValues {
	const description = findNamedStringProperty("@description", model.namedAttributes);
	const tableName = findNamedStringProperty("@table_name", model.namedAttributes);
	const properties = modelPropertyNodeListToModelPropertyFormValues(model.namedAttributes);
	const fields = model.fields.map(fieldNodeToFieldFormValues);

	return {
		name: model.name,
		description,
		tableName,
		...properties,
		fields,
	};
}

function modelPropertyNodeListToModelPropertyFormValues(namedAttributes: NamedAttributeNode[]) {
	const formValues: ModelPropertyFormValues = {
		hasPropertyAPIImmutable: false,
		hasPropertyAPIPrivate: false,
		hasPropertyAPIReadOnly: false,
		hasPropertyAPIUpdateOnly: false,
		hasPropertyNoGlobalSearch: false,
		hasPropertyNoHistory: false,
		hasPropertyNoPermissions: false,
	};

	const propertyNodes = findNamedPropertyNodeList("@properties", namedAttributes);
	for (let i = 0; i < propertyNodes.length; i++) {
		switch (propertyNodes[i].property) {
			case "APIImmutable":
				formValues.hasPropertyAPIImmutable = true;
				break;
			case "APIPrivate":
				formValues.hasPropertyAPIPrivate = true;
				break;
			case "APIReadOnly":
				formValues.hasPropertyAPIReadOnly = true;
				break;
			case "APIUpdateOnly":
				formValues.hasPropertyAPIUpdateOnly = true;
				break;
			case "NoGlobalSearch":
				formValues.hasPropertyNoGlobalSearch = true;
				break;
			case "NoHistory":
				formValues.hasPropertyNoHistory = true;
				break;
			case "NoPermissions":
				formValues.hasPropertyNoPermissions = true;
				break;
		}
	}

	return formValues;
}
