import React from "react";
import { Button, Row } from "@hex-insights/core";
import { ChevronDown, ChevronRight, Plus } from "../../Icons";
import styles from "./styles.module.css";

type ShowNumItemsProps = {
	numItems: number;
};
function ShowNumItems({ numItems }: ShowNumItemsProps) {
	return <React.Fragment>{numItems === 0 ? "(None)" : "(" + numItems + ")"}</React.Fragment>;
}

type CollapsibleItemsButtonProps = {
	name: string;
};
export function ShowItems({ name, numItems }: CollapsibleItemsButtonProps & ShowNumItemsProps) {
	return (
		<Row align="center" justify="space-between">
			Show {name} <ShowNumItems numItems={numItems} /> <ChevronRight className={styles["form-icon"]} size={20} />
		</Row>
	);
}

export function HideItems({ name }: CollapsibleItemsButtonProps) {
	return (
		<Row align="center" justify="space-between">
			Hide {name} <ChevronDown className={styles["form-icon"]} size={20} />
		</Row>
	);
}

type AddButtonProps = {
	onClick: () => void;
	disabled: boolean;
	name: string;
};
export function AddButton({ onClick, disabled, name }: AddButtonProps) {
	return (
		<Button variant="primary" size="small" onClick={onClick} disabled={disabled}>
			<Row align="center" justify="space-between">
				New {name} <Plus className={styles["form-icon"]} size={20} />
			</Row>
		</Button>
	);
}

type DeleteButtonProps = {
	onClick: () => void;
	disabled?: boolean;
	name?: string;
};
export function DeleteButton({ onClick, disabled, name }: DeleteButtonProps) {
	return (
		<Button variant="danger" size="small" disabled={disabled} onClick={onClick}>
			Delete {name}
		</Button>
	);
}
