import { pascalCaseRe, snakeCaseRe } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";

export function pascalOrSnakeCaseValidation(value: string) {
	return !value || pascalCaseRe.test(value) || snakeCaseRe.test(value)
		? valid()
		: invalid("Must be in PascalCase or snake_case.");
}

export function pascalCaseValidation(value: string) {
	return !value || pascalCaseRe.test(value) ? valid() : invalid("Must be in PascalCase.");
}

export function snakeCaseValidation(value: string) {
	return !value || snakeCaseRe.test(value) ? valid() : invalid("Must be in snake_case.");
}

const numberRe = /^-?(0|([1-9][0-9]*))(\.[0-9]+)?$/;
export function numberValidation(value: string) {
	return !value || numberRe.test(value) ? valid() : invalid("Must be a number.");
}

const keywords = new Set(["model", "edge"]);
export function keywordValidation(value: string) {
	return !value || !keywords.has(value) ? valid() : invalid(`Value '${value}' is a reserved keyword.`);
}
