import { NamedAttributeNode, NamedPropertyListNode, NamedStringAttributeNode, removeQuotes } from "@hex-insights/srsl";

export function findNamedStringProperty(name: string, namedAttributes: NamedAttributeNode[]) {
	let node: NamedAttributeNode | null = null;
	for (let i = 0; i < namedAttributes.length; i++) {
		if (namedAttributes[i].name === name) {
			node = namedAttributes[i];
			break;
		}
	}
	return node && node instanceof NamedStringAttributeNode ? removeQuotes(node.attribute.value) : "";
}

export function findNamedPropertyNodeList(name: string, namedAttributes: NamedAttributeNode[]) {
	let node: NamedAttributeNode | null = null;
	for (let i = 0; i < namedAttributes.length; i++) {
		if (namedAttributes[i].name === name) {
			node = namedAttributes[i];
			break;
		}
	}
	return node && node instanceof NamedPropertyListNode ? node.attribute : [];
}
