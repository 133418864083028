import React from "react";
import { ClassNameProps, makeClassName } from "@hex-insights/core";
import { IconButton } from "../IconButton";
import { ClipboardIcon } from "../Icons";
import styles from "./styles.module.css";

export type ClickToCopyProps = ClassNameProps & {
	getValue: () => string;
	disabled: boolean;
};

export function ClickToCopy({ className, getValue, disabled }: ClickToCopyProps) {
	const [fullClassName, setFullClassName] = React.useState(makeClassName(styles["clipboard-button"], className));

	const onClick = React.useCallback(() => {
		navigator.clipboard.writeText(getValue()).then(() => {
			setFullClassName(makeClassName(className, styles["checked"]));
			window.setTimeout(() => {
				setFullClassName(makeClassName(className));
			}, 1000);
		});
	}, [className, getValue]);

	return (
		<IconButton className={fullClassName} onClick={onClick} disabled={disabled}>
			<ClipboardIcon className={styles["clipboard-icon"]} size={20} />
		</IconButton>
	);
}
