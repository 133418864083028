import { getUUID } from "@hex-insights/core";
import { EdgeNode, MetaTagNode, ModelNode } from "@hex-insights/srsl";

type ModelNodeArgs = ConstructorParameters<typeof ModelNode>;

export class Model extends ModelNode {
	key: string;

	constructor(name: ModelNodeArgs[0], namedAttributes: ModelNodeArgs[1], fields: ModelNodeArgs[2], key?: string) {
		super(name, namedAttributes, fields);
		this.key = key ?? getUUID();
	}

	static fromModelNode(modelNode: ModelNode) {
		return new this(modelNode.name, modelNode.namedAttributes, modelNode.fields);
	}
}

type EdgeNodeArgs = ConstructorParameters<typeof EdgeNode>;

export class Edge extends EdgeNode {
	key: string;

	constructor(
		source: EdgeNodeArgs[0],
		sourceAlias: EdgeNodeArgs[1],
		edgeType: EdgeNodeArgs[2],
		target: EdgeNodeArgs[3],
		targetAlias: EdgeNodeArgs[4],
		namedAttributes: EdgeNodeArgs[5],
		key?: string,
	) {
		super(source, sourceAlias, edgeType, target, targetAlias, namedAttributes);
		this.key = key ?? getUUID();
	}

	static fromEdgeNode(edgeNode: EdgeNode) {
		return new this(
			edgeNode.source,
			edgeNode.sourceAlias,
			edgeNode.edgeType,
			edgeNode.target,
			edgeNode.targetAlias,
			edgeNode.namedAttributes,
		);
	}
}

type MetaTagNodeArgs = ConstructorParameters<typeof MetaTagNode>;

export class MetaTag extends MetaTagNode {
	key: string;

	constructor(tag: MetaTagNodeArgs[0], value: MetaTagNodeArgs[1], key?: string) {
		super(tag, value);
		this.key = key ?? getUUID();
	}

	static fromMetaTagNode(metaTagNode: MetaTagNode) {
		return new this(metaTagNode.tag, metaTagNode.value);
	}
}
