import { Row } from "@hex-insights/core";
import { ExternalLink } from "@hex-insights/router";
import styles from "./styles.module.css";

export function Footer() {
	return (
		<footer className={styles["footer"]}>
			<Row justify="space-between">
				<ExternalLink href="https://www.hexinsights.com">Hex Insights</ExternalLink>

				<span>
					SRSL Version: <code>0.3.4</code>
				</span>

				<span>&copy; {new Date().getFullYear()} Hex Insights, LLC</span>
			</Row>
		</footer>
	);
}
