import React from "react";
import { ClassNameProps } from "@hex-insights/core";
import { EdgeNode, EdgeType } from "@hex-insights/srsl";
import { Graphviz } from "@hpcc-js/wasm";

export type GraphProps = Partial<ClassNameProps> & {
	edges: EdgeNode[];
};

export function Graph({ className, edges }: GraphProps) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [graphviz, setGraphviz] = React.useState<any>(null); // Set to any because can't import the Graphviz type from wasm
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		(async () => {
			setIsLoading(true);
			const graphviz = await Graphviz.load();
			setGraphviz(graphviz);
			setIsLoading(false);
		})();
	}, []);

	React.useEffect(() => {
		if (graphviz === null || ref.current === null) {
			return;
		}
		const dotCode = toDotCode(edges);
		const svg = graphviz.layout(dotCode, "svg", "dot");
		ref.current.innerHTML = svg;
	}, [graphviz, edges]);

	return (
		<React.Fragment>
			{isLoading && edges.length > 0 && "Loading..."}
			<div ref={ref} className={className} />
		</React.Fragment>
	);
}

function toDotCode(edges: EdgeNode[]): string {
	return `digraph {\n${getGraphStyling()}\n${edges.map(edgeToDotCode).join("\n")}}`;
}

function getGraphStyling() {
	return [
		`rankdir="TB"`,
		`bgcolor="${nord.nord0}";`,
		`node [shape=rect, color="${nord.nord5}", fontcolor="${nord.nord5}"];`,
		`edge [color="${nord.nord4}"];`,
	].join("\n");
}

function edgeToDotCode(edge: EdgeNode) {
	switch (edge.edgeType) {
		case EdgeType.OneToOne:
			return `${edge.source} -> ${edge.target} [dir=both, arrowhead=normal, arrowtail=normal];`;
		case EdgeType.OneToMany:
			return `${edge.source} -> ${edge.target} [dir=both, arrowhead=inv, arrowtail=normal];`;
		case EdgeType.ManyToMany:
			return `${edge.source} -> ${edge.target} [dir=both, arrowhead=inv, arrowtail=inv];`;
	}
}
