import React from "react";
import { Button, Heading, List, Modal, Row } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";
import {
	containsModel,
	DeleteFunction,
	Model,
	ModelFormValues,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../Utilities";
import { ModelForm } from "../Forms";
import styles from "./styles.module.css";

type ModelListProps = {
	models: Model[];
	disabled?: boolean;
	update: UpdateFunction<Model>;
	del: DeleteFunction;
};
export function ModelList({ models, disabled, update, del }: ModelListProps) {
	const validate = React.useCallback(
		(key: string, formValues: ModelFormValues) => {
			if (containsModel(models, formValues, key)) {
				return invalid("Model already exists");
			}
			return valid();
		},
		[models],
	);

	if (models.length === 0) {
		return null;
	}

	return (
		<List className={styles["model-list"]}>
			{models.map((model) => (
				<ModelListItem
					key={model.key}
					model={model}
					validate={validate}
					update={update}
					del={del}
					disabled={disabled}
				/>
			))}
		</List>
	);
}

type ModelListItemProps = {
	model: Model;
	validate: UpdateValidationFunction<ModelFormValues>;
	update: UpdateFunction<Model>;
	del: DeleteFunction;
	disabled?: boolean;
};

function ModelListItem({ model, validate, update, del, disabled }: ModelListItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<List.Item className={styles["model-list-item"]}>
				<Row justify="space-between" align="center">
					<code>{model.name}</code>

					<Button variant="secondary" size="small" disabled={disabled} onClick={toggleIsModalOpen}>
						Edit
					</Button>
				</Row>
			</List.Item>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>Edit Model</Heading.H2>
					</Modal.Header>

					<Modal.Body>
						<ModelForm.ControlledUpdate
							model={model}
							validate={validate}
							update={update}
							del={del}
							onCancel={toggleIsModalOpen}
							onSuccess={toggleIsModalOpen}
						/>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
