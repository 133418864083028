import React from "react";
import { Button, Column, isString, Modal, ModalProps, RequiredKeys, Row, useLazyRef } from "@hex-insights/core";
import { useFormState } from "@hex-insights/forms";
import { NormalizationVisitor, Parser, Result, SchemaNode } from "@hex-insights/srsl";
import { schemaToCode } from "../../Utilities";
import { IconButton } from "../IconButton";
import { EditIcon } from "../Icons";

export type RawEditorButtonProps = {
	onClick: () => void;
};

export function RawEditorButton({ onClick }: RawEditorButtonProps) {
	return (
		<IconButton onClick={onClick}>
			<EditIcon size={20} />
		</IconButton>
	);
}

type RawEditorFormValues = {
	schemaText: string;
};

const normalizationVisitor = new NormalizationVisitor();

export type RawEditorModalProps = {
	schema: SchemaNode;
	onSchemaChange: (schema: SchemaNode) => void;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function RawEditorModal({ schema, onSchemaChange, ifRef, onClose }: RawEditorModalProps) {
	const [fileParseError, setFileParseError] = React.useState<string | null>(null);
	const initialSchemaText = useLazyRef(() => schemaToCode(schema));
	const formState = useFormState<RawEditorFormValues>({
		initialFormValues: { schemaText: initialSchemaText.current },
	});
	const {
		formValues: { schemaText },
		formSetFunctions: { schemaText: setSchemaText },
	} = formState;

	const onUpload = React.useCallback(
		(fileContent: string) => {
			setSchemaText(fileContent);
		},
		[setSchemaText],
	);

	const onSubmit = React.useCallback(() => {
		const parser = new Parser();
		const parseResult = parser.parse(schemaText);
		switch (parseResult.variant) {
			case Result.Variant.Ok:
				parseResult.value.accept(normalizationVisitor);
				onSchemaChange(parseResult.value);
				setFileParseError(null);
				onClose();
				break;

			case Result.Variant.Err:
				setFileParseError(parseResult.err.message);
				break;
		}
	}, [schemaText, onSchemaChange, onClose]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={formState.formChanged.schemaText}
			style={{ maxWidth: "60vw", height: "100vh" }}
		>
			<Modal.Body style={{ display: "flex", flexFlow: "column" }}>
				<pre style={{ marginBottom: "1rem" }}>
					<code>{fileParseError}</code>
				</pre>

				<form style={{ width: "100%", display: "flex", flexGrow: 1 }}>
					<Column justify="spaced-start" style={{ width: "100%", flexGrow: 1 }}>
						<FileUpload onUpload={onUpload} />

						<textarea
							name="schemaText"
							id="schema-text"
							value={schemaText}
							onChange={(e) => setSchemaText(e.target.value)}
							style={{
								color: "var(--general__field__input---color)",
								backgroundColor: "var(--general__field__input---background-color)",
								padding: "1rem",
								resize: "none",
								width: "100%",
								height: "100%",
								flexGrow: 1,
							}}
						></textarea>

						<Row justify="space-between">
							<Button variant="secondary" onClick={onClose}>
								Cancel
							</Button>

							<Button variant="primary" onClick={onSubmit}>
								Submit
							</Button>
						</Row>
					</Column>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export type FileUploadProps = {
	onUpload: (fileContent: string) => void;
};

export function FileUpload({ onUpload }: FileUploadProps) {
	const ref = React.useRef<HTMLInputElement>(null);

	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files;
			if (!files) {
				return;
			}
			const reader = new FileReader();
			reader.addEventListener("loadend", (event) => {
				if (event.target && isString(event.target.result)) {
					onUpload(event.target.result);
				}
			});
			reader.readAsText(files[0], "utf8");
		},
		[onUpload],
	);

	return <input ref={ref} type="file" accept=".srsl" onChange={onChange} />;
}
