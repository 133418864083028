import React from "react";
import {
	Button,
	ButtonWithConfirmation,
	Collapsible,
	Column,
	Heading,
	If,
	List,
	Paragraph,
	Row,
} from "@hex-insights/core";
import {
	BooleanField,
	composeSyncValidationFunctions,
	EditMode,
	FieldIf,
	Form,
	FormState,
	FormStateWithoutSubmissionState,
	FormType,
	RadioField,
	SelectField,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	TextField,
	useFormState,
	useOnSuccess,
	ValidationResult,
} from "@hex-insights/forms";
import {
	blankEdgeFormValues,
	ConvenientEdgeType,
	countActiveEdgeProperties,
	createFormFieldTypeOptions,
	CreateFunction,
	DeleteFunction,
	detailFormFieldTypeOptions,
	Edge,
	EdgeFormValues,
	edgeFormValuesToEdge,
	edgeNodeToEdgeFormValues,
	edgeOptions,
	getDefaultSourceAlias,
	getDefaultTargetAlias,
	keywordValidation,
	Model,
	onDeletePropertyValueOptions,
	snakeCaseValidation,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../../Utilities";
import { HideItems, ShowItems } from "../Buttons";
import styles from "../form-styles.module.css";

type SharedProps = {
	models: Model[];
	onCancel: () => void;
	onSuccess: () => void;
};

export type ControlledCreateProps = Omit<CreateProps, "formState">;

export function ControlledCreate({ models, validate, create, onCancel, onSuccess }: ControlledCreateProps) {
	const formState = useFormState<EdgeFormValues>({
		initialFormValues: blankEdgeFormValues,
		formType: FormType.Create,
	});

	return (
		<Create
			formState={formState}
			models={models}
			validate={validate}
			create={create}
			onCancel={onCancel}
			onSuccess={onSuccess}
		/>
	);
}

export type CreateProps = SharedProps & {
	formState: FormState<EdgeFormValues>;
	validate: (edge: EdgeFormValues) => ValidationResult;
	create: CreateFunction<Edge>;
};

export function Create({ formState, models, validate, create, onCancel, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<EdgeFormValues>) => {
			const validity = validate(formState.formValues);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<EdgeFormValues>({ _: validity.errors });
			}
			create(edgeFormValuesToEdge(formState.formValues));
			return submissionSuccess();
		},
		[validate, create],
	);

	useOnSuccess(formState, onSuccess);

	const modelOptions = React.useMemo(() => models.map(({ name }) => ({ value: name })), [models]);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<Column className={styles["edge-form-column"]}>
				<FormBody formState={formState} modelOptions={modelOptions} autoFocus />

				<Row className={styles["form-submit-buttons"]} justify="space-between">
					<Button
						variant="secondary"
						disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
						onClick={onCancel}
					>
						Cancel
					</Button>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Create Edge
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledUpdateProps = Omit<UpdateProps, "formState">;

export function ControlledUpdate({ edge, models, validate, update, del, onCancel, onSuccess }: ControlledUpdateProps) {
	const formState = useFormState<EdgeFormValues>({
		initialFormValues: edgeNodeToEdgeFormValues(edge),
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});

	return (
		<Update
			formState={formState}
			edge={edge}
			models={models}
			validate={validate}
			update={update}
			del={del}
			onCancel={onCancel}
			onSuccess={onSuccess}
		/>
	);
}

export type UpdateProps = SharedProps & {
	formState: FormState<EdgeFormValues>;
	edge: Edge;
	validate: UpdateValidationFunction<EdgeFormValues>;
	update: UpdateFunction<Edge>;
	del: DeleteFunction;
};

export function Update({ formState, edge, models, validate, update, del, onCancel, onSuccess }: UpdateProps) {
	const edgeKey = edge.key;

	const modelOptions = React.useMemo(() => models.map(({ name }) => ({ value: name })), [models]);

	const onDeleteClick = React.useCallback(() => {
		del(edgeKey);
	}, [del, edgeKey]);

	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<EdgeFormValues>) => {
			const validity = validate(edgeKey, formState.formValues);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<EdgeFormValues>({ _: validity.errors });
			}
			update(edgeKey, edgeFormValuesToEdge(formState.formValues, edgeKey));
			return submissionSuccess();
		},
		[edgeKey, validate, update],
	);

	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<FormBody formState={formState} modelOptions={modelOptions} />

			<Row className={styles["form-submit-buttons"]} justify="space-between">
				<Button
					variant="secondary"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onCancel}
				>
					Cancel
				</Button>

				<ButtonWithConfirmation
					variant="danger"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onDeleteClick}
					confirmationContent="Are you sure you want to delete this edge?"
				>
					Delete
				</ButtonWithConfirmation>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Update Edge
				</SubmitButton>
			</Row>
		</Form>
	);
}

const edgeAliasValidation = composeSyncValidationFunctions([snakeCaseValidation, keywordValidation]);

type FormBodyProps = {
	formState: FormState<EdgeFormValues>;
	modelOptions: { value: string }[];
	autoFocus?: boolean;
};

function FormBody({ formState, modelOptions, autoFocus }: FormBodyProps) {
	const sourceAliasDefault = React.useMemo(
		() =>
			formState.formValues.source
				? getDefaultSourceAlias(formState.formValues.source, formState.formValues.edgeType)
				: "(edge alias)",
		[formState.formValues.source, formState.formValues.edgeType],
	);
	const targetAliasDefault = React.useMemo(
		() =>
			formState.formValues.target
				? getDefaultTargetAlias(formState.formValues.target, formState.formValues.edgeType)
				: "(edge alias)",
		[formState.formValues.target, formState.formValues.edgeType],
	);

	const isFlipped = formState.formValues.edgeType === ConvenientEdgeType.ManyToOne;

	return (
		<Column justify="spaced-start">
			<If condition={formState.formSubmissionErrors._.length > 0}>
				<div className={styles["error-box"]}>
					<Paragraph>Encountered the following errors:</Paragraph>

					<List>
						{formState.formSubmissionErrors._.map((e) => (
							<List.Item key={e}>{e}</List.Item>
						))}
					</List>
				</div>
			</If>

			<Column justify="spaced-start">
				<SelectField
					formState={formState}
					name="source"
					label="Model"
					options={modelOptions}
					hint={isFlipped ? "Right Model" : "Left Model"}
					autoFocus={autoFocus}
				/>

				<TextField
					formState={formState}
					name="sourceAlias"
					label="Edge Alias"
					optional
					placeholder={sourceAliasDefault}
					immediateValidation={edgeAliasValidation}
					hint="A snake_cased alias"
				/>

				<SelectField formState={formState} name="edgeType" options={edgeOptions} />

				<SelectField
					formState={formState}
					name="target"
					label="Model"
					options={modelOptions}
					hint={isFlipped ? "Left Model" : "Right Model"}
				/>

				<TextField
					formState={formState}
					name="targetAlias"
					label="Edge Alias"
					optional
					placeholder={targetAliasDefault}
					immediateValidation={edgeAliasValidation}
					hint="A snake_cased alias"
				/>

				<Collapsible
					className={styles["collapsible-field-set"]}
					buttonVariant="secondary"
					buttonSize="small"
					expandButtonContent={
						<ShowItems name="Edge Properties" numItems={countActiveEdgeProperties(formState.formValues)} />
					}
					collapseButtonContent={<HideItems name="Edge Properties" />}
				>
					<Heading.H4>General Properties</Heading.H4>

					<Column justify="spaced-start">
						<BooleanField
							formState={formState}
							name="hasPropertyAPIPrivate"
							label="API Private"
							hint="Hides the edge from the API."
						/>

						<BooleanField
							formState={formState}
							name="hasPropertyAPIReadOnly"
							label="API Read-Only"
							hint="Disallows setting or updating the edge through the API."
						/>

						<BooleanField
							formState={formState}
							name="hasPropertyImmutable"
							label="Immutable"
							hint="Disallows changes to the edge after creation."
						/>

						<BooleanField
							formState={formState}
							name="hasPropertyOnDelete"
							label="On Delete"
							hint="Sets behavior when the referenced instance is deleted."
						/>
						<FieldIf
							formState={formState}
							name="propertyValueOnDelete"
							condition={formState.formValues.hasPropertyOnDelete}
						>
							<RadioField formState={formState} name="propertyValueOnDelete" options={onDeletePropertyValueOptions} />
						</FieldIf>
					</Column>

					<Heading.H4>
						Properties of {formState.formValues.source}{" "}
						{formState.formValues.sourceAlias ? `(${formState.formValues.sourceAlias}) ` : ""}
					</Heading.H4>
					<Column justify="spaced-start">
						<BooleanField
							formState={formState}
							name="leftHasPropertyAPIUpdateOnly"
							label="API Update-Only"
							hint="Disallows setting a value for the edge when creating an instance of the model through the API. In other words, the initial value is set automatically by the server."
						/>

						<BooleanField
							formState={formState}
							name="leftHasPropertyRequired"
							label="Required"
							hint="Requires a value at all times."
						/>

						<BooleanField
							formState={formState}
							name="leftHasPropertySearchable"
							label="Searchable"
							hint="Search queries will include this edge."
						/>

						<RadioField
							formState={formState}
							name="leftPropertyValueCreateFormFieldType"
							label="Create Form Field Type"
							hint="Controls create form handling for this edge."
							optional
							options={createFormFieldTypeOptions}
							blankValue={null}
						/>

						<RadioField
							formState={formState}
							name="leftPropertyValueDetailFormFieldType"
							label="Detail Form Field Type"
							hint="Controls detail form handling for this edge."
							optional
							options={detailFormFieldTypeOptions}
							blankValue={null}
						/>
					</Column>

					<Heading.H4>
						Properties of {formState.formValues.target}{" "}
						{formState.formValues.targetAlias ? `(${formState.formValues.targetAlias}) ` : ""}
					</Heading.H4>
					<Column justify="spaced-start">
						<BooleanField
							formState={formState}
							name="rightHasPropertyAPIUpdateOnly"
							label="API Update-Only"
							hint="Disallows setting a value for the edge when creating an instance of the model through the API. In other words, the initial value is set automatically by the server."
						/>

						<BooleanField
							formState={formState}
							name="rightHasPropertyRequired"
							label="Required"
							hint="Requires a value at all times."
						/>

						<BooleanField
							formState={formState}
							name="rightHasPropertySearchable"
							label="Searchable"
							hint="Search queries will include this edge."
						/>

						<RadioField
							formState={formState}
							name="rightPropertyValueCreateFormFieldType"
							label="Create Form Field Type"
							hint="Controls create form handling for this edge."
							optional
							options={createFormFieldTypeOptions}
							blankValue={null}
						/>

						<RadioField
							formState={formState}
							name="rightPropertyValueDetailFormFieldType"
							label="Detail Form Field Type"
							hint="Controls detail form handling for this edge."
							optional
							options={detailFormFieldTypeOptions}
							blankValue={null}
						/>
					</Column>
				</Collapsible>
			</Column>
		</Column>
	);
}
