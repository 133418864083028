import { Edge, MetaTag, Model } from "../types";
import { edgeFormValuesToEdge } from "./FormTranslation";
import { EdgeFormValues, MetaTagFormValues, ModelFormValues } from "./FormValues";

export function containsModel(models: Model[], modelFormValues: ModelFormValues, ignoreKey?: string) {
	for (let i = 0; i < models.length; i++) {
		const model = models[i];
		if (ignoreKey !== undefined && model.key === ignoreKey) {
			continue;
		}
		if (areEquivalentModels(model, modelFormValues)) {
			return true;
		}
	}
	return false;
}

function areEquivalentModels(a: Model, b: ModelFormValues) {
	return a.name === b.name;
}

export function containsEdge(edges: Edge[], edgeFormValues: EdgeFormValues, ignoreKey?: string) {
	for (let i = 0; i < edges.length; i++) {
		const edge = edges[i];
		if (ignoreKey !== undefined && edge.key === ignoreKey) {
			continue;
		}
		if (areEquivalentEdges(edge, edgeFormValues)) {
			return true;
		}
	}
	return false;
}

function areEquivalentEdges(a: Edge, b: EdgeFormValues) {
	const bEdge = edgeFormValuesToEdge(b);
	return a.toSimpleString() === bEdge.toSimpleString();
}

export function containsMetaTag(metaTags: MetaTag[], metaTagFormValues: MetaTagFormValues, ignoreKey?: string) {
	for (let i = 0; i < metaTags.length; i++) {
		const metaTag = metaTags[i];
		if (ignoreKey !== undefined && metaTag.key === ignoreKey) {
			continue;
		}
		if (areEquivalentMetaTags(metaTags[i], metaTagFormValues)) {
			return true;
		}
	}
	return false;
}

function areEquivalentMetaTags(a: MetaTag, b: MetaTagFormValues) {
	return a.tag === b.tag;
}
