import React from "react";
import { Button, Column, Heading, Modal, Row } from "@hex-insights/core";
import { invalid, valid } from "@hex-insights/forms";
import { containsModel, CreateFunction, DeleteFunction, Model, ModelFormValues, UpdateFunction } from "../../Utilities";
import { ModelForm } from "../Forms";
import { ModelList } from "../ModelList";

export type ModelsEditorProps = {
	models: Model[];
	create: CreateFunction<Model>;
	update: UpdateFunction<Model>;
	del: DeleteFunction;
};

export function ModelsEditor({ models, create, update, del }: ModelsEditorProps) {
	return (
		<Column align="start" justify="start">
			<ModelList models={models} update={update} del={del} />

			<ModelCreator models={models} create={create} />
		</Column>
	);
}

type ModelCreatorProps = {
	models: Model[];
	create: CreateFunction<Model>;
};

function ModelCreator({ models, create }: ModelCreatorProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const validate = React.useCallback(
		(formValues: ModelFormValues) => {
			if (containsModel(models, formValues)) {
				return invalid("Model already exists");
			}
			return valid();
		},
		[models],
	);

	return (
		<React.Fragment>
			<Row justify="center" style={{ width: "100%" }}>
				<Button variant="primary" onClick={toggleIsModalOpen}>
					Add New Model
				</Button>
			</Row>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>New Model</Heading.H2>
					</Modal.Header>

					<Modal.Body>
						<ModelForm.ControlledCreate
							validate={validate}
							create={create}
							onCancel={toggleIsModalOpen}
							onSuccess={toggleIsModalOpen}
						/>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}
