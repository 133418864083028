import { Column, Heading, Row } from "@hex-insights/core";
import styles from "./styles.module.css";

export function Header() {
	return (
		<header className={styles["header"]}>
			<Row justify="spaced-start">
				<div className={styles["logo-container"]}>
					<img src="https://cdn.hexinsights.net/latest/logo/logo-white.svg" alt="Hex Insights Logo" />
				</div>

				<Column justify="spaced-start" verticalSpacing="0.25rem" style={{ margin: "var(--heading--level-1---margin)" }}>
					<span className={styles["hex-insights"]}>Hex Insights</span>
					<Heading.H1 noMargin>Schema Editor</Heading.H1>
				</Column>
			</Row>
		</header>
	);
}
