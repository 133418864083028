import { Button, Column, Grid, Heading, If, Modal, Row, useToggle } from "@hex-insights/core";
import { Graph } from "../Graph";
import { Highlight } from "../Highlight";
import { RawEditorModal } from "../RawEditor";
import { EdgesEditor } from "./EdgesEditor";
import { MetaTagsEditor } from "./MetaTagsEditor";
import { ModelsEditor } from "./ModelsEditor";
import { useSchemaEditorState } from "./use-schema-editor-state";

export function SchemaEditor() {
	const { isOn: isRawEditorOpen, toggle: toggleIsRawEditorOpen } = useToggle();

	const {
		schema,
		models,
		edges,
		metaTags,
		acceptSchema,
		createModel,
		updateModel,
		deleteModel,
		createEdge,
		updateEdge,
		deleteEdge,
		createMetaTag,
		updateMetaTag,
		deleteMetaTag,
	} = useSchemaEditorState();

	return (
		<Column justify="space-between">
			<Row justify="center" align="center">
				<Graph edges={edges} />
			</Row>

			<Column justify="spaced-start">
				<If condition={models.length + edges.length + metaTags.length === 0}>
					<Heading.H2>Existing Project: </Heading.H2>

					<Row justify="center">
						<Button variant="primary" onClick={toggleIsRawEditorOpen}>
							Upload a File
						</Button>
					</Row>

					<Heading.H2>New Project: </Heading.H2>
				</If>

				<Grid
					responsiveColumns={{
						0: 1,
						750: 2,
						1100: 3,
					}}
					gap="2rem"
				>
					<ModelsEditor models={models} create={createModel} update={updateModel} del={deleteModel} />

					<EdgesEditor models={models} edges={edges} create={createEdge} update={updateEdge} del={deleteEdge} />

					<MetaTagsEditor metaTags={metaTags} create={createMetaTag} update={updateMetaTag} del={deleteMetaTag} />
				</Grid>
			</Column>

			<Highlight schema={schema} onOpenRawEditor={toggleIsRawEditorOpen} />

			<Modal.If condition={isRawEditorOpen}>
				<RawEditorModal schema={schema} onSchemaChange={acceptSchema} onClose={toggleIsRawEditorOpen} />
			</Modal.If>
		</Column>
	);
}
