import React from "react";
import { Button, ButtonWithConfirmation, Column, Row } from "@hex-insights/core";
import {
	EditMode,
	Form,
	FormState,
	FormStateWithoutSubmissionState,
	FormType,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	TextField,
	useFormState,
	useOnSuccess,
	ValidationResult,
} from "@hex-insights/forms";
import {
	blankMetaTagFormValues,
	CreateFunction,
	CreateValidationFunction,
	DeleteFunction,
	MetaTag,
	MetaTagFormValues,
	metaTagFormValuesToMetaTag,
	metaTagNodeToMetaTagFormValues,
	pascalCaseValidation,
	UpdateFunction,
	UpdateValidationFunction,
} from "../../../Utilities";
import styles from "../form-styles.module.css";

type SharedProps = {
	onCancel: () => void;
	onSuccess: () => void;
};

export type ControlledCreateProps = Omit<CreateProps, "formState">;

export function ControlledCreate({ validate, create, onCancel, onSuccess }: ControlledCreateProps) {
	const formState = useFormState({ initialFormValues: blankMetaTagFormValues, formType: FormType.Create });

	return <Create formState={formState} validate={validate} create={create} onCancel={onCancel} onSuccess={onSuccess} />;
}

export type CreateProps = SharedProps & {
	formState: FormState<MetaTagFormValues>;
	validate: CreateValidationFunction<MetaTagFormValues>;
	create: CreateFunction<MetaTag>;
};

export function Create({ formState, validate, create, onCancel, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<MetaTagFormValues>) => {
			const value = {
				tag: formState.formValues.tag,
				value: formState.formValues.value.trim(),
			};
			const validity = validate(value);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<MetaTagFormValues>({
					tag: { _: validity.errors },
				});
			}

			create(metaTagFormValuesToMetaTag(value));
			return submissionSuccess();
		},
		[validate, create],
	);

	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<FormBody formState={formState} autoFocus />

			<Row className={styles["form-submit-buttons"]} justify="space-between">
				<Button
					variant="secondary"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onCancel}
				>
					Cancel
				</Button>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Create Meta Tag
				</SubmitButton>
			</Row>
		</Form>
	);
}

export type ControlledUpdateProps = Omit<UpdateProps, "formState">;

export function ControlledUpdate({ metaTag, validate, update, del, onCancel, onSuccess }: ControlledUpdateProps) {
	const formState = useFormState({
		initialFormValues: metaTagNodeToMetaTagFormValues(metaTag),
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});

	return (
		<Update
			formState={formState}
			metaTag={metaTag}
			validate={validate}
			update={update}
			del={del}
			onCancel={onCancel}
			onSuccess={onSuccess}
		/>
	);
}

export type UpdateProps = SharedProps & {
	formState: FormState<MetaTagFormValues>;
	metaTag: MetaTag;
	validate: UpdateValidationFunction<MetaTagFormValues>;
	update: UpdateFunction<MetaTag>;
	del: DeleteFunction;
};

export function Update({ formState, metaTag, validate, update, del, onCancel, onSuccess }: UpdateProps) {
	const metaTagKey = metaTag.key;

	const onDeleteClick = React.useCallback(() => {
		del(metaTagKey);
	}, [del, metaTagKey]);

	const onSubmit = React.useCallback(
		async (formState: FormStateWithoutSubmissionState<MetaTagFormValues>) => {
			const value = {
				tag: formState.formValues.tag,
				value: formState.formValues.value.trim(),
			};
			const validity = validate(metaTagKey, value);
			if (ValidationResult.isFailure(validity)) {
				return submissionFailure<MetaTagFormValues>({
					tag: { _: validity.errors },
				});
			}
			update(metaTagKey, metaTagFormValuesToMetaTag(value, metaTagKey));
			return submissionSuccess();
		},
		[metaTagKey, validate, update],
	);

	useOnSuccess(formState, onSuccess);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications>
			<FormBody formState={formState} />

			<Row className={styles["form-submit-buttons"]} justify="space-between">
				<Button
					variant="secondary"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onCancel}
				>
					Cancel
				</Button>

				<ButtonWithConfirmation
					variant="danger"
					disabled={!SubmissionStatus.isSubmittable(formState.submissionStatus)}
					onClick={onDeleteClick}
					confirmationContent="Are you sure you want to delete this meta tag?"
				>
					Delete
				</ButtonWithConfirmation>

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Update Meta Tag
				</SubmitButton>
			</Row>
		</Form>
	);
}

type FormBodyProps = {
	formState: FormState<MetaTagFormValues>;
	autoFocus?: boolean;
};

function FormBody({ formState, autoFocus }: FormBodyProps) {
	return (
		<Column justify="spaced-start">
			<TextField
				formState={formState}
				name="tag"
				immediateValidation={pascalCaseValidation}
				hint="A PascalCased tag"
				autoFocus={autoFocus}
			/>

			<TextField formState={formState} name="value" />
		</Column>
	);
}
