export type FieldPropertyFormValues = {
	hasPropertyAPIPrivate: boolean;
	hasPropertyAPIReadOnly: boolean;
	hasPropertyAPIUpdateOnly: boolean;
	hasPropertyAPIComputed: boolean;
	hasPropertyAnnotations: boolean;
	propertyValueAnnotations: string;
	hasPropertyComputed: boolean;
	hasPropertyDefault: boolean;
	propertyValueDefault: string;
	hasPropertyImmutable: boolean;
	hasPropertyMatch: boolean;
	propertyValueMatch: string;
	hasPropertyMax: boolean;
	propertyValueMax: string;
	hasPropertyMaxLen: boolean;
	propertyValueMaxLen: number | null;
	hasPropertyMin: boolean;
	propertyValueMin: string;
	hasPropertyMinLen: boolean;
	propertyValueMinLen: number | null;
	hasPropertyNegative: boolean;
	hasPropertyNillable: boolean;
	hasPropertyNonNegative: boolean;
	hasPropertyAllowsEmpty: boolean;
	hasPropertyOptional: boolean;
	hasPropertyOrderField: boolean;
	hasPropertyDefaultOrderField: boolean;
	hasPropertyPositive: boolean;
	hasPropertyRange: boolean;
	propertyValueRangeMin: string;
	propertyValueRangeMax: string;
	hasPropertySensitive: boolean;
	hasPropertyStructTag: boolean;
	propertyValueStructTag: string;
	hasPropertyUnique: boolean;
	hasPropertyValidate: boolean;
	propertyValueValidate: string;
};

export type FieldFormValues = {
	name: string;
	type: string;
	typeValues: TypeValue[];
} & FieldPropertyFormValues;

export type TypeValue = { value: string };

const blankFieldPropertyFormValues: FieldPropertyFormValues = {
	hasPropertyAPIPrivate: false,
	hasPropertyAPIReadOnly: false,
	hasPropertyAPIUpdateOnly: false,
	hasPropertyAPIComputed: false,
	hasPropertyAnnotations: false,
	propertyValueAnnotations: "",
	hasPropertyComputed: false,
	hasPropertyDefault: false,
	propertyValueDefault: "",
	hasPropertyImmutable: false,
	hasPropertyMatch: false,
	propertyValueMatch: "",
	hasPropertyMax: false,
	propertyValueMax: "",
	hasPropertyMaxLen: false,
	propertyValueMaxLen: null,
	hasPropertyMin: false,
	propertyValueMin: "",
	hasPropertyMinLen: false,
	propertyValueMinLen: null,
	hasPropertyNegative: false,
	hasPropertyNillable: false,
	hasPropertyNonNegative: false,
	hasPropertyAllowsEmpty: false,
	hasPropertyOptional: false,
	hasPropertyOrderField: false,
	hasPropertyDefaultOrderField: false,
	hasPropertyPositive: false,
	hasPropertyRange: false,
	propertyValueRangeMin: "",
	propertyValueRangeMax: "",
	hasPropertySensitive: false,
	hasPropertyStructTag: false,
	propertyValueStructTag: "",
	hasPropertyUnique: false,
	hasPropertyValidate: false,
	propertyValueValidate: "",
};

export const blankFieldFormValues: FieldFormValues = {
	name: "",
	type: "",
	typeValues: [],
	...blankFieldPropertyFormValues,
};

export const blankTypeValueFormValues: TypeValue = {
	value: "",
};

export function countActiveFieldProperties(formValues: FieldPropertyFormValues) {
	let count = 0;
	if (formValues.hasPropertyAPIPrivate) {
		count++;
	}
	if (formValues.hasPropertyAPIReadOnly) {
		count++;
	}
	if (formValues.hasPropertyAPIUpdateOnly) {
		count++;
	}
	if (formValues.hasPropertyAPIComputed) {
		count++;
	}
	if (formValues.hasPropertyAnnotations) {
		count++;
	}
	if (formValues.hasPropertyComputed) {
		count++;
	}
	if (formValues.hasPropertyDefault) {
		count++;
	}
	if (formValues.hasPropertyImmutable) {
		count++;
	}
	if (formValues.hasPropertyMatch) {
		count++;
	}
	if (formValues.hasPropertyMax) {
		count++;
	}
	if (formValues.hasPropertyMaxLen) {
		count++;
	}
	if (formValues.hasPropertyMin) {
		count++;
	}
	if (formValues.hasPropertyMinLen) {
		count++;
	}
	if (formValues.hasPropertyNegative) {
		count++;
	}
	if (formValues.hasPropertyNillable) {
		count++;
	}
	if (formValues.hasPropertyNonNegative) {
		count++;
	}
	if (formValues.hasPropertyAllowsEmpty) {
		count++;
	}
	if (formValues.hasPropertyOptional) {
		count++;
	}
	if (formValues.hasPropertyOrderField) {
		count++;
	}
	if (formValues.hasPropertyDefaultOrderField) {
		count++;
	}
	if (formValues.hasPropertyPositive) {
		count++;
	}
	if (formValues.hasPropertyRange) {
		count++;
	}
	if (formValues.hasPropertySensitive) {
		count++;
	}
	if (formValues.hasPropertyStructTag) {
		count++;
	}
	if (formValues.hasPropertyUnique) {
		count++;
	}
	if (formValues.hasPropertyValidate) {
		count++;
	}
	return count;
}
