import {
	addCodeBlockDelimiters,
	addQuotes,
	CodeBlockNode,
	FieldNode,
	FieldTypeNode,
	NumberLiteralNode,
	PropertyNode,
	removeCodeBlockDelimiters,
	removeQuotes,
	StringLiteralNode,
	TypeValueNode,
} from "@hex-insights/srsl";
import { FieldFormValues, FieldPropertyFormValues } from "../FormValues";

export function fieldFormValuesToFieldNode(formValues: FieldFormValues): FieldNode {
	return new FieldNode(
		formValues.name,
		new FieldTypeNode(formValues.type),
		formValues.typeValues.map(({ value }) => new TypeValueNode(value)),
		fieldPropertyFormValuesToFieldPropertyNodeList(formValues),
	);
}

function fieldPropertyFormValuesToFieldPropertyNodeList(formValues: FieldPropertyFormValues) {
	const properties: PropertyNode[] = [];
	if (formValues.hasPropertyAPIPrivate) {
		properties.push(new PropertyNode("APIPrivate", []));
	}
	if (formValues.hasPropertyAPIReadOnly) {
		properties.push(new PropertyNode("APIReadOnly", []));
	}
	if (formValues.hasPropertyAPIUpdateOnly) {
		properties.push(new PropertyNode("APIUpdateOnly", []));
	}
	if (formValues.hasPropertyAPIComputed) {
		properties.push(new PropertyNode("APIComputed", []));
	}
	if (formValues.hasPropertyAnnotations) {
		properties.push(
			new PropertyNode("Annotations", [new CodeBlockNode(addCodeBlockDelimiters(formValues.propertyValueAnnotations))]),
		);
	}
	if (formValues.hasPropertyComputed) {
		properties.push(new PropertyNode("Computed", []));
	}
	if (formValues.hasPropertyDefault) {
		properties.push(new PropertyNode("Default", [new StringLiteralNode(formValues.propertyValueDefault)]));
	}
	if (formValues.hasPropertyImmutable) {
		properties.push(new PropertyNode("Immutable", []));
	}
	if (formValues.hasPropertyMatch) {
		properties.push(
			new PropertyNode("Match", [new CodeBlockNode(addCodeBlockDelimiters(formValues.propertyValueMatch))]),
		);
	}
	if (formValues.hasPropertyMax) {
		properties.push(new PropertyNode("Max", [new StringLiteralNode(formValues.propertyValueMax)]));
	}
	if (formValues.hasPropertyMaxLen) {
		properties.push(
			new PropertyNode("MaxLen", [new NumberLiteralNode((formValues.propertyValueMaxLen ?? 0).toString())]),
		);
	}
	if (formValues.hasPropertyMin) {
		properties.push(new PropertyNode("Min", [new StringLiteralNode(formValues.propertyValueMin)]));
	}
	if (formValues.hasPropertyMinLen) {
		properties.push(
			new PropertyNode("MinLen", [new NumberLiteralNode((formValues.propertyValueMinLen ?? 0).toString())]),
		);
	}
	if (formValues.hasPropertyNegative) {
		properties.push(new PropertyNode("Negative", []));
	}
	if (formValues.hasPropertyNillable) {
		properties.push(new PropertyNode("Nillable", []));
	}
	if (formValues.hasPropertyNonNegative) {
		properties.push(new PropertyNode("NonNegative", []));
	}
	if (formValues.hasPropertyAllowsEmpty) {
		properties.push(new PropertyNode("AllowsEmpty", []));
	}
	if (formValues.hasPropertyOptional) {
		properties.push(new PropertyNode("Optional", []));
	}
	if (formValues.hasPropertyOrderField) {
		properties.push(new PropertyNode("OrderField", []));
	}
	if (formValues.hasPropertyDefaultOrderField) {
		properties.push(new PropertyNode("DefaultOrderField", []));
	}
	if (formValues.hasPropertyPositive) {
		properties.push(new PropertyNode("Positive", []));
	}
	if (formValues.hasPropertyRange) {
		properties.push(
			new PropertyNode("Range", [
				new StringLiteralNode(formValues.propertyValueRangeMin),
				new StringLiteralNode(formValues.propertyValueRangeMax),
			]),
		);
	}
	if (formValues.hasPropertySensitive) {
		properties.push(new PropertyNode("Sensitive", []));
	}
	if (formValues.hasPropertyStructTag) {
		properties.push(
			new PropertyNode("StructTag", [new StringLiteralNode(addQuotes(formValues.propertyValueStructTag))]),
		);
	}
	if (formValues.hasPropertyUnique) {
		properties.push(new PropertyNode("Unique", []));
	}
	if (formValues.hasPropertyValidate) {
		properties.push(
			new PropertyNode("Validate", [new CodeBlockNode(addCodeBlockDelimiters(formValues.propertyValueValidate))]),
		);
	}

	return properties;
}

export function fieldNodeToFieldFormValues(fieldNode: FieldNode): FieldFormValues {
	const properties = fieldPropertyNodeListToFieldPropertyFormValues(fieldNode.properties);

	return {
		name: fieldNode.name,
		type: fieldNode.type.name,
		typeValues: fieldNode.typeValues.map(({ value }) => ({ value })),
		...properties,
	};
}

function fieldPropertyNodeListToFieldPropertyFormValues(properties: PropertyNode[]) {
	const formValues: FieldPropertyFormValues = {
		hasPropertyAPIPrivate: false,
		hasPropertyAPIReadOnly: false,
		hasPropertyAPIUpdateOnly: false,
		hasPropertyAPIComputed: false,
		hasPropertyAnnotations: false,
		propertyValueAnnotations: "",
		hasPropertyComputed: false,
		hasPropertyDefault: false,
		propertyValueDefault: "",
		hasPropertyImmutable: false,
		hasPropertyMatch: false,
		propertyValueMatch: "",
		hasPropertyMax: false,
		propertyValueMax: "",
		hasPropertyMaxLen: false,
		propertyValueMaxLen: null,
		hasPropertyMin: false,
		propertyValueMin: "",
		hasPropertyMinLen: false,
		propertyValueMinLen: null,
		hasPropertyNegative: false,
		hasPropertyNillable: false,
		hasPropertyNonNegative: false,
		hasPropertyAllowsEmpty: false,
		hasPropertyOptional: false,
		hasPropertyOrderField: false,
		hasPropertyDefaultOrderField: false,
		hasPropertyPositive: false,
		hasPropertyRange: false,
		propertyValueRangeMin: "",
		propertyValueRangeMax: "",
		hasPropertySensitive: false,
		hasPropertyStructTag: false,
		propertyValueStructTag: "",
		hasPropertyUnique: false,
		hasPropertyValidate: false,
		propertyValueValidate: "",
	};

	for (let i = 0; i < properties.length; i++) {
		const property = properties[i];
		switch (property.property) {
			case "APIPrivate":
				formValues.hasPropertyAPIPrivate = true;
				break;
			case "APIReadOnly":
				formValues.hasPropertyAPIReadOnly = true;
				break;
			case "APIUpdateOnly":
				formValues.hasPropertyAPIUpdateOnly = true;
				break;
			case "APIComputed":
				formValues.hasPropertyAPIComputed = true;
				break;
			case "Annotations":
				formValues.hasPropertyAnnotations = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueAnnotations = removeCodeBlockDelimiters(property.propertyValues[0].value);
				}
				break;
			case "Computed":
				formValues.hasPropertyComputed = true;
				break;
			case "Default":
				formValues.hasPropertyDefault = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueDefault = property.propertyValues[0].value;
				}
				break;
			case "Immutable":
				formValues.hasPropertyImmutable = true;
				break;
			case "Match":
				formValues.hasPropertyMatch = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueMatch = removeCodeBlockDelimiters(property.propertyValues[0].value);
				}
				break;
			case "Max":
				formValues.hasPropertyMax = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueMax = property.propertyValues[0].value;
				}
				break;
			case "MaxLen":
				formValues.hasPropertyMaxLen = true;
				if (property.propertyValues.length > 0) {
					const value = Number(property.propertyValues[0].value);
					formValues.propertyValueMaxLen = !isNaN(value) ? value : null;
				}
				break;
			case "Min":
				formValues.hasPropertyMin = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueMin = property.propertyValues[0].value;
				}
				break;
			case "MinLen":
				formValues.hasPropertyMinLen = true;
				if (property.propertyValues.length > 0) {
					const value = Number(property.propertyValues[0].value);
					formValues.propertyValueMinLen = !isNaN(value) ? value : null;
				}
				break;
			case "Negative":
				formValues.hasPropertyNegative = true;
				break;
			case "Nillable":
				formValues.hasPropertyNillable = true;
				break;
			case "NonNegative":
				formValues.hasPropertyNonNegative = true;
				break;
			case "AllowsEmpty":
				formValues.hasPropertyAllowsEmpty = true;
				break;
			case "Optional":
				formValues.hasPropertyOptional = true;
				break;
			case "OrderField":
				formValues.hasPropertyOrderField = true;
				break;
			case "DefaultOrderField":
				formValues.hasPropertyDefaultOrderField = true;
				break;
			case "Positive":
				formValues.hasPropertyPositive = true;
				break;
			case "Range":
				formValues.hasPropertyRange = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueRangeMin = property.propertyValues[0].value;
				}
				if (property.propertyValues.length > 1) {
					formValues.propertyValueRangeMax = property.propertyValues[1].value;
				}
				break;
			case "Sensitive":
				formValues.hasPropertySensitive = true;
				break;
			case "StructTag":
				formValues.hasPropertyStructTag = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueStructTag = removeQuotes(property.propertyValues[0].value);
				}
				break;
			case "Unique":
				formValues.hasPropertyUnique = true;
				break;
			case "Validate":
				formValues.hasPropertyValidate = true;
				if (property.propertyValues.length > 0) {
					formValues.propertyValueValidate = removeCodeBlockDelimiters(property.propertyValues[0].value);
				}
				break;
		}
	}

	return formValues;
}
