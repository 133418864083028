import { Button, ChildrenProps, ClassNameProps, makeClassName } from "@hex-insights/core";
import styles from "./styles.module.css";

export type IconButtonProps = {
	onClick: () => void;
	disabled?: boolean;
} & Partial<ClassNameProps> &
	ChildrenProps;

export function IconButton({ onClick, disabled, className, children }: IconButtonProps) {
	return (
		<Button
			onClick={onClick}
			variant="custom"
			disabled={disabled}
			className={makeClassName(styles["icon-button"], className)}
		>
			{children}
		</Button>
	);
}
