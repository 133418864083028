import { Versioning } from "@hex-insights/app-modules";

const localVersion = process.env.REACT_APP_VERSION ?? "";

export function VersionController() {
	console.log(localVersion);
	return <Versioning.VersionController version={localVersion} getCurrentVersion={getCurrentVersion} />;
}

async function getCurrentVersion() {
	const response = await fetch("/meta.json", { cache: "no-cache" });
	if (!response.ok) {
		return undefined;
	}
	let responseJSON: any;
	try {
		responseJSON = await response.json();
	} catch {
		return undefined;
	}
	return responseJSON.version;
}
